<template>
  <div>
    <ValidationObserver ref="form">
      <b-row>
        <b-col md="12">
          <b-form-group
            label="Catcher"
            label-class="font-weight-bolder"
            label-cols="5"
            content-cols="7"
          >
            <b-form-input
              v-model.trim="contactInfo.catcher"
              type="text"
              disabled
            />
          </b-form-group>
          <b-form-group
            label="Program"
            label-class="font-weight-bolder"
            label-cols="5"
            content-cols="7"
          >
            <b-form-input
              v-model.trim="contactInfo.program"
              type="text"
              disabled
            />
          </b-form-group>
          <b-form-group
            label="Date"
            label-class="font-weight-bolder"
            label-cols="5"
            content-cols="7"
          >
            <b-form-input v-model="contactInfo.date" type="email" disabled />
          </b-form-group>

          <b-form-group
            v-if="parent_source == 2"
            label="Contact Method"
            label-class="font-weight-bolder"
            label-cols="5"
            content-cols="7"
          >
            <b-form-input
              v-model.trim="contactInfo.contact_method"
              type="text"
              :class="['bg-white capitalize-sn']"
            />
          </b-form-group>

          <b-form-group
            label="Start Dialogue"
            label-class="font-weight-bolder"
            label-cols="5"
            content-cols="7"
          >
            <VueToggles
              style="margin-top: 5px"
              height="27"
              width="70"
              checked-text="YES"
              unchecked-text="NO"
              :checked-bg="dialogue ? '#31af42' : '#FF6045'"
              unchecked-bg="#FF6045"
              :value="dialogue"
              font-weight="bold"
              @click="editReply(2)"
              disabled
            />
          </b-form-group>

          <b-form-group
            label="Potential"
            label-class="font-weight-bolder"
            label-cols="5"
            content-cols="7"
          >
            <div class="d-flex align-items-center">
              <VueToggles
                class="mr-0"
                style="margin-top: 5px"
                height="27"
                width="70"
                checked-text="YES"
                unchecked-text="NO"
                :checked-bg="potential ? '#31af42' : '#FF6045'"
                unchecked-bg="#FF6045"
                :value="potential"
                font-weight="bold"
                :disabled="(!(isTeamLeader || isSupervisor || isCeo) || contactInfo.reason_other || disabledPotential) || isResponsibleLead"
                @click="editReply(1)"
              />
              <feather-icon
                v-if="!potential"
                icon="AlertCircleIcon"
                class="text-primary ml-1 cursor-pointer"
                style="margin-top: 5px"
                size="25"
                v-b-tooltip.hover.bottom="reason"
              />
            </div>
          </b-form-group>
          <b-form-group
            v-if="notLoadingReasonsOthers"
            label="Others"
            label-class="font-weight-bolder"
            label-cols="5"
            content-cols="7"
          >
            <div>
              <b-form-select
                v-model="contactInfo.reason_other"
                :options="listReasonOthers"
                class="mb-3"
                value-field="id"
                text-field="reason"
                :disabled="!(isTeamLeader || isSupervisor || isCeo) || isResponsibleLead"
                @input="selectReasonOthers"
              ></b-form-select>
            </div>
          </b-form-group>
        </b-col>
        <b-modal
          id="modalReasonNoPotential"
          v-model="modalReasonNoPotential"
          size="sm"
          title="Choose a Reason"
          centered
          no-close-on-backdrop
          @ok="confirmPotential"
          @cancel.prevent="closeModalReasonNotPotential"
          @hidden="closeModalReasonNotPotential"
        >
          <b-row>
            <b-col cols="12">
              <ValidationProvider v-slot="{ errors }" rules="required">
                <modal-reason-no-potential
                  ref="modalReasonNoPotential"
                  v-model="reasonId"
                  @input="getReason"
                  :reason="typeReason"
                />
                <div v-if="errors[0]" class="invalid-feedback">
                  Reason is {{ errors[0] }}
                </div>
              </ValidationProvider>
            </b-col>
            <!-- <b-col
              v-if="isCeo || isSupervisor || isTeamLeader"
              cols="1"
              class="p-0"
            >
              <b-button size="sm" variant="primary" @click="addReason()">
                +
              </b-button>
            </b-col> -->

            <b-col cols="12">
              <template #modal-footer="{ ok, cancel }">
                <div
                  style="
                    display: flex;
                    justify-content: end;
                    align-items: center;
                  "
                >
                  <b-button
                    variant="outline-danger"
                    class="mr-1"
                    @click="cancel()"
                  >
                    Cancel
                  </b-button>
                  <b-button
                    variant="primary"
                    class="rounded btn-ui-green"
                    @click="ok()"
                  >
                    Confirm
                  </b-button>
                </div>
              </template>
            </b-col>
          </b-row>
        </b-modal>
      </b-row>
    </ValidationObserver>
    <modal-add-reason-not-potential
      v-if="modalReason"
      :show="modalReason"
      @onClose="closeModalAddReasonNotPotential"
    />
  </div>
</template>

<script>
// Components
import VueToggles from "vue-toggles";
import SNLeadsService from "@/views/social-network/services/leads";
import ModalReasonNoPotential from "./ModalReasonNotPotential.vue";
import ModalAddReasonNotPotential from "../../../create/components/ModalAddReasonNotPotential.vue";
import { mapGetters } from "vuex";
import WithoutLeadsService from "@/views/social-network/views/leads/tabs/without-leads/services/without-leads.service";
// Services
import moment from "moment";

export default {
  components: {
    VueToggles,
    ModalReasonNoPotential,
    "modal-add-reason-not-potential": ModalAddReasonNotPotential,
  },
  props: {
    contactInfo: {
      type: Object,
      default: () => ({
        catcher: "",
        potential: "",
        program: "",
        date: "",
        dialogue: "",
        contact_method: "",
        reason: "",
        organic: "",
        reason_other: null,
      }),
    },
    reply: {
      type: Object,
      default: () => ({}),
    },
    parent_source: {
      type: Number,
      default: () => 0,
    },
  },
  data() {
    return {
      dialogue: true,
      potential: false,
      modalReasonNoPotential: false,
      reasonId: null,
      modalReason: false,
      organic: true,
      isEditPotential: true,
      motiveNotPotentialReason: false,
      lead: {},
      reason: null,
      typeReason: 6, // not potential, 8 : potential
      listReasonOthers: [],
      notLoadingReasonsOthers: false,
      disabledPotential: false,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    dateNow() {
      return moment().format("MM/DD/YYYY");
    },
    isResponsibleLead() {
      if (this.$route.params.id) {
        return !(this.$store.getters['NotificationStore/G_IS_RESPONSIBLE_CEO'].is_responsible === this.currentUser.user_id || this.$store.getters['NotificationStore/G_IS_RESPONSIBLE_CEO'].is_responsible === null);
      }
      return false;
    },
  },
  async created() {
    await this.getReasonOthers();
    await this.getReasonNoPotential();
  },
  mounted() {
    if (this.contactInfo.dialogue == "NO") {
      this.dialogue = false;
    } else {
      this.dialogue = true;
    }
    this.potential = this.contactInfo.potential != "NO";
    if (this.contactInfo.organic == "NO" || this.contactInfo.organic == null) {
      this.organic = false;
    } else {
      this.organic = true;
    }
  },
  methods: {
    addReason() {
      this.modalReason = true;
    },
    closeModalAddReasonNotPotential() {
      this.$refs.modalReasonNoPotential.getReasonsNoPotential();
      this.modalReason = false;
    },
    async editReply(type) {
      const confirm = await this.showGenericConfirmSwal({
        text: "Do you want to edit the information?",
      });

      if (confirm.value) {
        if (type == 1) {
          this.typeReason = this.potential ? 6 : 8; // 6: reasons for not potential, 8: reasons for potential
          this.modalReasonNoPotential = true;
        } else {
          this.dialogue = !this.dialogue;
          this.contactInfo.dialogue = this.dialogue ? 1 : 2;
          await this.updateChanges(type, this.contactInfo.dialogue);
          this.reply.dialogue = this.dialogue ? "YES" : "NO";
        }
      }
    },
    confirmPotential() {
      this.$refs.form.validate().then((success) => {
        if (success) {
          const typePotential = this.potential ? 2 : 1; // 2 : update to no potential, 1: potential
          this.contactInfo.potential = this.potential ?? false;
          this.updateChanges(1, typePotential);
          this.reply.potencial = "NO"; //this.potential ? "YES" : "NO";
          this.modalReasonNoPotential = false;
          this.potential = !this.potential;
          this.$emit('updatePotential',  this.potential ? "YES": "NO" )
        }
      });
    },
    async updateChanges(type, value) {
      try {
        const params = {
          type,
          value,
          id_reply: this.contactInfo.reply_id,
          reason_id: this.reasonId ? this.reasonId.id : null,
          lead_id: this.$route.params.id,
          user_id: this.currentUser.user_id,
        };

        const response = await SNLeadsService.updatePotentialDialogById(params);

        if (response.status == 200) {
          this.updateChangesLead(value);
        }
      } catch (error) {
        this.showErrorSwal(error);
      }
    },
    async updateChangesLead(value) {
      try {
        let params = {
          type: value,
          lead_id: this.$route.params.id,
          motive: this.reasonId ? this.reasonId.id : null,
          user_id: this.currentUser.user_id,
          status: value == 2 ? 0 : null,
        };
        const result = await WithoutLeadsService.updateStatusPotencial(params);
        if (result.status == 200) {
          this.showSuccessSwal("Success!", "OPERATION SUCCESSFULLY");
        }
      } catch (error) {
        this.showErrorSwal(error);
      }
    },
    closeModalReasonNotPotential() {
      this.modalReasonNoPotential = false;
    },
    getReason(item) {
      this.reason = item.reason;
    },
    async getReasonNoPotential() {
      try {
        const params = {
          id_reply: this.contactInfo.reply_id,
        };
        const response = await SNLeadsService.getReasonNotPotential(params);
        if (response.status == 200) {
          this.reason = response.data[0] ? response.data[0].description : null;
        }
      } catch (error) {
        this.showErrorSwal(error);
      }
    },

    async getReasonOthers() {
      this.notLoadingReasonsOthers = false;
      const { data } = await SNLeadsService.getReasonsNotPotential({
        type_reason: 7,
      });

      this.listReasonOthers = data;
      this.notLoadingReasonsOthers = true;
    },

    async selectReasonOthers(value) {
      if(value){
        const params = {

        id_reply: this.contactInfo.reply_id,
        id_reason: value,
        user_id: this.currentUser.user_id,
      };
      const data = await SNLeadsService.addUpdateReasonOther(params);
      this.$emit('updateOthers', value)

      this.disabledPotential = true;
      this.showToast()
      }

    },
  },
  watch: {
    "contactInfo.dialogue": function () {
      this.dialogue = this.contactInfo.dialogue !== "NO";
    },
  },
};
</script>

<style scoped>
.w-pre {
  width: 40% !important;
}
.capitalize-sn {
  text-transform: capitalize;
}
</style>
