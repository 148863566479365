<template>
  <b-card body-class="px-0">
    <template #header>
      <b-card-title class="card-title-address">
        <div>Contact Information</div>
      </b-card-title>
    </template>

    <div class="p-relative">
      <b-tabs
        id="ci-source-tabs"
        v-model="sourceIndex"
        pills
        active-nav-item-class="bg-primary box-shadow-primary border-primary primary"
        nav-class="border-bottom-vertical-tab pb-1 rounded-0"
        lazy
      >
        <b-tab
          v-for="(source, index) in allInfo"
          :key="index"
          lazy
          title-link-class="sub-tab px-3 ml-2"
        >
          <template #title>
            {{ source.source }}
          </template>
          <b-tabs
            id="ci-subsource-tabs"
            v-model="subSourceIndex"
            pills
            active-nav-item-class="bg-orange-tab"
            content-class="padding-vertical-tab"
            nav-class="border-bottom-vertical-tab nav-pill-warning mt-2 pb-1 rounded-0"
            lazy
          >
            <b-tab
              v-for="subSource in source.sub_source"
              :key="subSource.name"
              title-item-class="text-center ml-3"
              title-link-class="bg-warning-tab"
            >
              <template #title>
                <span>{{ subSource.name }}</span>
              </template>
              <div class="ml-3">
                <b-tabs
                  id="ci-vertical-tabs"
                  v-model="replyIndex"
                  active-nav-item-class="font-weight-bold text-uppercase nav-link-sn-active"
                  nav-class="border-right-vertical-tab border-bottom-0 h-100 rounded-0"
                  lazy
                  vertical
                >
                  <b-tab
                    v-for="(reply, index) in subSource.replies"
                    :key="index"
                    title-item-class="w-vertical-tab"
                    title-link-class="nav-link-vertical nav-link-sn mx-0 border-light"
                  >
                    <template #title>
                      <div
                        :class="[
                          'vertical-item',
                          activeReply == index ? 'vertical-item-active' : '',
                        ]"
                        @click="setActiveColor(index)"
                      >
                        {{ reply.count_reply }}
                      </div>
                      <span @click="setActiveColor(index)">{{
                        reply.type_reply
                      }}</span>
                      <feather-icon
                        v-if="!validationEditReplay"
                        icon="Edit2Icon"
                        style="margin-left: 0.5rem"
                        size="17"
                        @click="editReply(reply)"
                      />
                    </template>
                    <b-row>
                      <b-col :md="source.id == 1 ? 9 : 12">
                        <b-row style="padding-top: 37px">
                          <b-col cols="12" md="4">
                            <contact-information
                              ref="contactInformation"
                              :contact-info="{
                                catcher: reply.name_catcher,
                                potential: reply.potencial,
                                program: reply.fanpage,
                                date: reply.created_at,
                                dialogue: reply.dialogue,
                                organic: reply.organic,
                                reply_id: reply.reply_id,
                                contact_method: reply.contact_method,
                                reason: reply.reason,
                              }"
                              :reply="reply"
                              :parent_source="source.id"
                            />
                          </b-col>
                          <b-col cols="12" md="8">
                            <contact-notes
                              :reply="reply"
                              :personal-info="personalInfo"
                              :lead="lead"
                            />
                          </b-col>
                        </b-row>
                      </b-col>
                      <b-col v-show="source.id == 1" md="3">
                        <contact-flyer
                          :fyer-reply="{
                            flyer_id: reply.flyer_id,
                            reply_id: reply.reply_id,
                            route: reply.flyer_route,
                            stAD: personalInfo.stateAd,
                            fanpage_id: reply.fanpage_id,
                            fanpage_name: reply.fanpage,
                            flyer_state: reply.flyer_state,
                          }"
                          :reply="reply"
                        />
                      </b-col>
                      <b-col md="6" class="pr-0">
                        <contact-tasks
                          :reply-id="reply.reply_id"
                          :required-fields-for-create-crm-task="
                            requiredFieldsForCreateCrmTask
                          "
                          :modul="modul"
                          :lead="lead"
                        />
                      </b-col>
                      <b-col md="6" class="pl-0">
                        <contact-files
                          :reply-id="reply.reply_id"
                          :lead="lead"
                        />
                      </b-col>
                    </b-row>
                  </b-tab>
                </b-tabs>
              </div>
            </b-tab>
          </b-tabs>
        </b-tab>
        <template #tabs-end>
          <b-button
            size="sm"
            variant="primary"
            class="btn-icon position-plus"
            title="Add new Reply"
            @click="openModalAddReply()"
          >
            <feather-icon icon="PlusIcon" />
          </b-button>
        </template>
      </b-tabs>
    </div>

    <modal-add-reply
      v-if="showModalAddReply"
      :reply="replyData"
      :lead="lead"
      :personal-mobile="personalMobile"
      :personal-info="personalInfo"
      @updatedMobile="updatedMobile"
      @onClose="closeModalAddReply"
    />
    <modal-edit-reply
      v-if="showModalEditReply"
      :reply-prop="allReplyToEdit"
      :personal-mobile="personalMobile"
      :personal-info-prop="personalInfo"
      @updatedMobile="updatedMobile"
      @close="closeModalAddReply"
    />
  </b-card>
</template>

<script>
// Components
import ModalEditReply from "@/views/social-network/views/leads/tabs/new-leads/views/dashboard/components/contact-information/ModalEditContact.vue";
import SNLeadsService from "@/views/social-network/services/leads";
import RoiReportsService from "@/views/commons/components/roi-reports/roi-reports.service";
import moment from "moment";
import ContactInformation from "./ContactInformation.vue";
import ContactNotes from "./contact-notes/ContactNotes.vue";
import ContactFlyer from "./ContactFlyer.vue";
import ContactTasks from "./ContactTasks.vue";
import ContactFiles from "./ContactFiles.vue";
import ModalAddReply from "@/views/social-network/views/leads/tabs/new-leads/views/dashboard/components/contact-information/ModalAddContact.vue";

// Services

export default {
  components: {
    ContactInformation,
    ContactNotes,
    ContactFlyer,
    ContactTasks,
    ContactFiles,
    ModalAddReply,
    ModalEditReply,
  },
  props: {
    personalInfo: {
      type: Object,
      default: () => ({}),
    },
    requiredFieldsForCreateCrmTask: {
      type: Object,
      default: () => ({}),
    },
    catcher: {
      type: Number,
      default: () => 1,
    },
    lead: {
      type: Object,
    },
    modul: [String, Number],
    personalMobile: {
      type: Object,
    },
  },
  data() {
    return {
      sources: [],
      subSources: [],
      replies: [],
      replyShow: [],
      showModalEditReply: false,
      replyData: {
        typeReply: null,
        idSubSource: null,
        id_lead: "",
        idCatcher: this.catcher,
        idProgram: null,
        idMethod: null,
        motiveNoAnswer: null,
        motiveNoMobile: null,
        motiveNoFilter: null,
        addEvidence: true,
        addEvidenceMobile: true,
        phonem: null,
        is_organic: false,

        filterLead: false,
        // Task
        title: "",
        flyerId: null,
        seller: null,
        subject: "CALL",
        hour: "",
        from: "",
        to: "",
        date: "",
        sms_status: false,
        due_date: "",
        assign: null,
        attend: null,
        // appointment
        appTitle: "",
        appSeller: null,
        location: "",
        appDescription: "",
        appDate: "",
        appFrom: "",
        appTo: "",
        created_users: null,
        appType: "event",
        dateSp: null,
        dateEn: null,
        hourMsn: null,
        state_h: "CA",
        multiply: 1,
      },

      indexSource: 0,
      indexSubSource: 0,
      indexReply: 0,

      allInfo: [],
      showModalAddReply: false,
      sourceIndex: 0,
      subSourceIndex: 0,
      replyIndex: 0,
      activeReply: 0,
      doneReload: false,
      allReplyToEdit: "",
      validationEditReplay: true,
    };
  },
  computed: {},
  async mounted() {
    setTimeout(async () => {
      await this.getInfoReplies();
      await this.getValidationEditReplies();
    }, 1000);

    this.replyData.id_lead = this.lead.id;
  },
  methods: {
    updatedMobile() {
      this.$emit("updatedMobile");
    },
    closeModalEditReply() {
      this.showModalEditReply = false;
    },
    openModalAddReply(type) {
      this.replyData.type = type;
      this.showModalAddReply = true;
    },
    async closeModalAddReply(data) {
      try {
        if (data) {
          await this.tabChanges(data);
        }
        this.showModalAddReply = false;
        this.showModalEditReply = false;
      } catch (e) {
        console.error(e);
      }
    },
    setActiveColor(index) {
      this.activeReply = index;
    },
    async tabChanges(data) {
      await this.getInfoReplies();
      // Index Parent
      let sIndex = 0;
      let ssIndex = 0;
      let rIndex = 0;
      await this.allInfo.forEach((source, index) => {
        if (source.id == data[0].source_id) {
          sIndex = index;
        }
      });

      // Index SubSource
      await this.allInfo[sIndex].sub_source.forEach((subSource, index) => {
        if (subSource.sub_source_id == data[0].sub_source_id) {
          ssIndex = index;
        }
      });

      // Index Reply
      const variants = await this.allInfo[sIndex].sub_source[ssIndex];

      await variants.replies.forEach((reply, index) => {
        if (reply.reply_id == data[0].reply_id) {
          rIndex = index;
        }
      });
      // this.$refs.contactInformation.updateDialogue()
      setTimeout(() => {
        this.sourceIndex = sIndex;
        this.subSourceIndex = ssIndex;
        this.replyIndex = rIndex;
      }, 400);
    },
    editReply(reply) {
      this.showModalEditReply = true;
      this.allReplyToEdit = { ...reply, ...this.replyData };
    },
    async getInfoReplies() {
      try {
        const leadId = this.$route.params.id;

        const response = await SNLeadsService.getReplies({
          id_lead: leadId,
        });
        console.log('response',response);
        if (response.status == 200) {
          this.allInfo = response.data;
          this.doneReload = true;
        }
      } catch (error) {
        throw error;
      }
    },
    async getValidationEditReplies() {
      try {
        const response = await RoiReportsService.getValidationEditReplay({});

        if (response.status == 200) {
          if (response.data <= moment().format("YYYY-MM-DD")) {
            this.validationEditReplay = false;
          } else {
            this.validationEditReplay = true;
          }
        }
      } catch (error) {
        throw error;
      }
    },
  },
};
</script>

<style lang="scss">
.fs-18 {
  font-size: 18px;
}

.position-plus {
  position: absolute;
  right: 2rem;
}

#ci-source-tabs {
  .nav-item .nav-link {
    border-radius: 6px !important;
  }

  .border-bottom-vertical-tab {
    border-bottom: 1px rgb(223, 223, 223) solid !important;
  }
}

#ci-subsource-tabs {
  .border-bottom-vertical-tab {
    border-bottom: 1px rgb(223, 223, 223) solid !important;
  }

  .nav-item .nav-link {
    border-radius: 6px !important;
  }
}

#ci-vertical-tabs {
  .nav {
    align-items: flex-start;
  }

  .border-right-vertical-tab {
    border-right: 1px rgb(223, 223, 223) solid !important;
  }

  .nav-item {
    .nav-link {
      margin-bottom: 20px;
      border: none !important;
    }
  }

  .nav-item .nav-link::after {
    background: white !important;
  }

  .nav-item .nav-link.active {
    color: #ff6045 !important;
  }
}

#sn-d-ci-tasks {
  .nav-item {
    .nav-link {
      margin-bottom: 0px;
      border: none !important;
    }
  }

  .nav-item .nav-link.active {
    color: white !important;
  }
}

.vertical-item {
  width: 21px;
  height: 21px;
  background: rgb(226, 217, 217);
  font-size: 15px !important;
  display: flex;
  justify-content: center;
  align-content: center;
  border-radius: 5px;
  margin-right: 10px;
  color: grey !important;

  &-active {
    background: #ff6045 !important;
    color: white !important;
    font-size: 15px !important;
  }
}
</style>