<template>
  <b-modal
    v-model="onControl"
    title="Edit Contact"
    title-tag="h3"
    modal-class="modal-primary"
    no-close-on-backdrop
    size="lg"
    @hidden="close(null)"
  >
    <sales-process-component
      ref="sales-process"
      :personal-info="personalInfoProp"
      :reply="replyObj"
      :personal-mobile="personalMobile"
      :is-today="isToday"
      @updatedMobile="updatedMobile"
      @close="close"
    />
    <template #modal-footer>
      <b-button
        variant="primary"
        @click="saveReply"
      > Save
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import SalesProcessComponent from '@/views/social-network/views/leads/tabs/new-leads/views/dashboard/components/contact-information/components/SalesProcessComponent.vue'
import moment from 'moment'

export default {
  components: {
    SalesProcessComponent,
  },
  props: {
    replyProp: {
      type: Object,
      default: () => ({}),
    },
    personalInfoProp: {
      type: Object,
      default: () => ({}),
    },
    personalMobile: {
      type: Object,
    },
    contact: {
      type: Object,
    }
  },
  data() {
    return {
      onControl: false,
      replyObj: {}
    }
  },
  computed: {
    isToday() {
      return moment().format('MM/DD/YYYY') === this.replyProp.created_at
    },
  },
  async created() {
    this.onControl = true
    this.replyObj = {...this.contact}

  },
  methods: {
    close(data) {
      this.$emit('close', data)
    },
    async saveReply() {
      await this.$refs['sales-process'].saveReply()
      this.$emit('onEditReply')
    },
    updatedMobile() {
      this.$emit('updatedMobile')
    },
  },
}
</script>

<style scoped>

</style>
