<template>
  <div>
    <b-card body-class="px-2" class="border border-light rounded h-100">
      <template #header>
        <b-card-title class="card-title-address px-2">
          <div>Notes</div>
        </b-card-title>
      </template>

      <div class="col-md-12" style="height: 243px">
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <div v-if="btnsEditNotes">
                <SmartNoteInput
                  :key="note.id"
                  :rows="6"
                  ref="smartNoteInput"
                  v-model="note.text"
                  @original="handleOriginalText"
                  :show-optimize="btnsEditNotes"
                  :options="{
                    modules: { toolbar: false },
                  }"
                  required
                >
                  <template v-slot:more-items>
                    <span>
                      <b-button
                        variant="warning"
                        size="sm"
                        class="btn-icon"
                        @click="openModalNotes(2)"
                        v-if="lead.count_notes != 0"
                        style="margin-right: 5px"
                      >
                        <feather-icon icon="ListIcon"></feather-icon>
                      </b-button>
                      <!-- disable if the lead is old -->
                      <b-button
                        variant="warning"
                        size="sm"
                        class="btn-icon"
                        v-if="lead.count_notes != 0"
                        @click="openModalNotes(1)"
                        style="margin-right: 5px"
                      >
                        <feather-icon icon="PlusIcon"></feather-icon>
                      </b-button>

                      <b-button
                        variant="outline-danger"
                        size="sm"
                        @click="cancelEditNote"
                        v-if="btnsEditNotes"
                        style="margin-right: 5px"
                      >
                        Cancel
                      </b-button>
                      <b-button
                        variant="primary"
                        size="sm"
                        @click="updateImportantNote"
                        v-if="btnsEditNotes"
                        :disabled="!useOptimize"
                      >
                        Confirm
                      </b-button>
                    </span>
                  </template>
                </SmartNoteInput>
                <span class="text-danger" v-if="!useOptimize">
                  Optimized message is required
                </span>
              </div>

              <div v-else>
                <span>
                  <b-button
                    variant="warning"
                    size="sm"
                    class="btn-icon"
                    @click="openModalNotes(2)"
                    v-if="lead.count_notes != 0"
                    style="margin-right: 5px"
                  >
                    <feather-icon icon="ListIcon"></feather-icon>
                  </b-button>
                  <!-- disable if the lead is old -->
                  <b-button
                    variant="warning"
                    size="sm"
                    class="btn-icon"
                    v-if="lead.count_notes != 0"
                    @click="openModalNotes(1)"
                    style="margin-right: 5px"
                  >
                    <feather-icon icon="PlusIcon"></feather-icon>
                  </b-button>

                  <b-button
                    variant="outline-danger"
                    size="sm"
                    @click="cancelEditNote"
                    v-if="btnsEditNotes"
                    style="margin-right: 5px"
                  >
                    Cancel
                  </b-button>
                  <b-button
                    variant="primary"
                    size="sm"
                    @click="updateImportantNote"
                    v-if="btnsEditNotes"
                  >
                    Confirm
                  </b-button>
                </span>
                <div
                  class="text-editor"
                  @click="editNote"
                  v-html="note.text"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-card>

    <modal-notes
      v-if="showModalNotes"
      :notes="this.dataHistoryNotes"
      :leadName="this.personalInfo.nickname"
      :type="this.type"
      :userId="this.currentUser.user_id"
      :leadId="this.personalInfo.id_lead"
      :imp="note.text == '' ? true : false"
      :replyId="reply.reply_id"
      :oldReply="reply.old_reply == 1"
      @newImportant="setNewImportantNote"
      @onClose="closeModalNotes"
    ></modal-notes>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

// Components
import ModalNotes from "./ModalNotes.vue";
import SmartNoteInput from "@/commons/smart-input/SmartNoteInput.vue";

// Services
import NotesService from "@/views/crm/services/notes";

export default {
  props: {
    notes: {
      type: Object,
      default: () => ({}),
    },
    reply: {
      type: Object,
      default: () => ({}),
    },
    personalInfo: {
      type: Object,
      default: () => ({}),
    },
    lead: {
      type: Object,
    },
  },
  components: {
    ModalNotes,
    SmartNoteInput,
  },
  data() {
    return {
      note: {
        id: this.reply.id_note,
        user_id: "",
        lead_id: this.lead.id,
        text: this.reply.content_note,
        reply_id: this.reply.reply_id,
        originalText: "",
      },
      dataHistoryNotes: [],
      type: "",
      btnsEditNotes: false,
      changeOriginal: false,
      // Modals
      showModalNotes: false,
      useOptimize: false,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    isResponsibleLead() {
      if (this.$route.params.id) {
        console.log(
          this.$store.getters["NotificationStore/G_IS_RESPONSIBLE_CEO"]
        );
        return !(
          this.$store.getters["NotificationStore/G_IS_RESPONSIBLE_CEO"]
            .is_responsible === this.currentUser.user_id ||
          this.$store.getters["NotificationStore/G_IS_RESPONSIBLE_CEO"]
            .is_responsible === null
        );
      }
      return false;
    },
  },
  methods: {
    handleOriginalText(text) {
      this.note.original_text = text;
      this.useOptimize = true;
    },
    async openModalNotes(type) {
      try {
        if (type == 2) {
          this.addPreloader();
          const response = await NotesService.getLeadNotes({
            lead_id: this.lead.id,
            reply_id: this.reply.reply_id,
            oldReply: this.reply.old_reply == 1,
          });

          if (response.status == 200) {
            this.dataHistoryNotes = response.data;
            this.type = 0;
            this.modalHistoryNotes = true;
          }
          this.removePreloader();
        }

        this.type = type;
        this.showModalNotes = true;
      } catch (error) {
        throw error;
      }
    },
    closeModalNotes() {
      this.showModalNotes = false;
    },
    editNote() {
      this.btnsEditNotes = true;
    },
    cancelEditNote() {
      this.btnsEditNotes = false;
    },
    setNewImportantNote(newVal) {
      this.note = newVal;
      this.note.user_id = this.note.created_by;
      this.reply.content_note = newVal.text;
      this.reply.original_text = newVal.original_text;
      this.reply.id_note = newVal.id;
    },
    async updateImportantNote() {
      try {
        const confirm = await this.showConfirmSwal();

        if (confirm.value) {
          const response = await NotesService.postUpdateLeadNote(this.note);
          if (response.status == 200 || response.status == 201) {
            // this.reply.content_note = response.data[0].text;
            // this.reply.id_note = response.data[0].id;
            await this.getHistoryLeadNotes();
          }
          this.btnsEditNotes = false;
        }
      } catch (error) {
        throw error;
      } finally {
        this.btnsEditNotes = false;
      }
    },
    async getHistoryLeadNotes() {
      try {
        const { data, status } = await NotesService.getLeadNotes({
          reply_id: this.reply.reply_id,
          lead_id: this.lead.id,
          oldReply: false,
        });
        if (status === 200) {
          let importantNote = data.filter((item) => item.important == 1);
          if (importantNote.length > 0) {
            this.note.id = importantNote[0].id;
            this.note.user_id = importantNote[0].created_by;
            this.note.text = importantNote[0].text;
            this.note.lead_id = this.lead.id;
            this.note.reply_id = this.reply.reply_id;
            this.note.original_text = importantNote[0].original_text;
          }
        }
      } catch (error) {
        this.showErrorSwal(error);
      }
    },
  },
  async created() {
    this.note.user_id = this.currentUser.user_id;
    await this.getHistoryLeadNotes();
  },
};
</script>

<style lang="scss" scoped>
.text-editor {
  margin-top: 10px;
  padding: 10px;
  border: 1px solid #e2e8f0;
  border-radius: 5px;
  height: 180px;
  overflow-y: auto;
}
</style>
