<template>
  <div class="p-2">
    <transition name="fade">
      <div>
        <ValidationObserver ref="form">
          <b-row>
            <b-col cols="6" class="text-left">
              <b-form-group
                id="fieldset-horizontal"
                label-class="font-bureau-style font-weight-normal color-gray-input-sn"
                label-cols-sm="4"
                label-for="input-horizontal"
                label-cols-lg="6"
                content-cols-sm
                content-cols-lg="6"
                label="Did you start dialogue?"
              >
                <VueToggles
                  class="vue-toggle"
                  height="31"
                  width="90"
                  checked-text="YES"
                  unchecked-text="NO"
                  checked-bg="#FF6A6A"
                  :dot-color="reply.addEvidence ? 'white' : '#FF6A6A'"
                  unchecked-bg="lightgrey"
                  :value="reply.addEvidence"
                  font-weight="bold"
                  :disabled="disableDialogue"
                  @click="onClickToggleAnswer"
                />
              </b-form-group>
            </b-col>
            <b-col v-if="!reply.addEvidence" cols="6" class="text-left">
              <ValidationProvider
                v-slot="{ errors }"
                rules="required"
                name="MotiveNoAnswer"
              >
                <b-form-group
                  id="fieldset-horizontal"
                  label="Motive"
                  label-for="st-ad"
                  :state="errors[0] ? false : null"
                  label-class="font-bureau-style font-weight-normal color-gray-input-sn"
                  label-cols-sm="4"
                  label-cols-lg="4"
                  content-cols-sm
                  content-cols-lg="8"
                  class="m-0"
                >
                  <div
                    class="d-flex justify-content-between align-items-center"
                  >
                    <b-form-select
                      v-model="reply.motiveNoAnswer"
                      class="form-control"
                      :state="errors[0] ? false : null"
                      :options="motivesNoAnswerOptions"
                      text-field="description"
                      value-field="id"
                    />
                    <feather-icon
                      v-if="isCeo || isSupervisor || isTeamLeader"
                      class="text-primary cursor-pointer"
                      size="26"
                      style="margin-left: 1rem"
                      icon="PlusCircleIcon"
                      @click="openEditMotiveModalOn(1)"
                    />
                  </div>
                  <div
                    v-if="errors[0]"
                    class="text-error-sn"
                    style="padding-top: 5px"
                  >
                    Motive {{ errors[0] }}
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>
          </b-row>
          <b-row v-if="reply.addEvidence">
            <b-col cols="6" class="text-left">
              <b-form-group
                id="fieldset-horizontal"
                label-class="font-bureau-style font-weight-normal color-gray-input-sn"
                label-cols-sm="4"
                label-for="input-horizontal"
                label-cols-lg="6"
                content-cols-sm
                content-cols-lg="6"
                label="Did you get the phone?"
              >
                <VueToggles
                  class="vue-toggle"
                  height="31"
                  width="90"
                  checked-text="YES"
                  unchecked-text="NO"
                  checked-bg="#FF6A6A"
                  :dot-color="reply.addEvidenceMobile ? 'white' : '#FF6A6A'"
                  unchecked-bg="lightgrey"
                  :value="reply.addEvidenceMobile"
                  font-weight="bold"
                  :disabled="disableMobile"
                  @click="onClickTogglePhone"
                />
              </b-form-group>
            </b-col>
            <b-col v-if="!reply.addEvidenceMobile" cols="6" class="text-left">
              <ValidationProvider
                v-slot="{ errors }"
                rules="required"
                name="MotiveMobile"
              >
                <b-form-group
                  id="fieldset-horizontal"
                  label="Motive"
                  label-for="st-ad"
                  :state="errors[0] ? false : null"
                  label-class="font-bureau-style font-weight-normal color-gray-input-sn"
                  label-cols-sm="4"
                  label-cols-lg="4"
                  content-cols-sm
                  content-cols-lg="8"
                  class="m-0"
                >
                  <div
                    class="d-flex justify-content-between align-items-center"
                  >
                    <b-form-select
                      v-model="reply.motiveNoMobile"
                      class="form-control"
                      :state="errors[0] ? false : null"
                      :options="motivesNoMobileOptions"
                      text-field="description"
                      value-field="id"
                    />
                    <feather-icon
                      v-if="isCeo || isSupervisor || isTeamLeader"
                      class="text-primary cursor-pointer"
                      size="26"
                      style="margin-left: 1rem"
                      icon="PlusCircleIcon"
                      @click="openEditMotiveModalOn(2)"
                    />
                  </div>
                  <div
                    v-if="errors[0]"
                    class="text-error-sn"
                    style="padding-top: 5px"
                  >
                    Motive {{ errors[0] }}
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>
            <b-col
              v-if="reply.addEvidenceMobile"
              cols="6"
              class="text-left p-0"
            >
              <ValidationProvider
                v-slot="{ errors }"
                rules="required|min:14"
                name="input-Phone(M)"
              >
                <standar-form-group label="Phone(M)">
                  <b-input-group>
                    <b-form-input
                      v-model="reply.phonem"
                      v-mask="'(###) ###-####'"
                      :state="errors[0] ? false : null"
                      :disabled="editPhone"
                      @input="validateMobile"
                    />
                  </b-input-group>
                </standar-form-group>
              </ValidationProvider>
            </b-col>
          </b-row>
          <template v-if="reply.addEvidence">
            <template
              v-if="
                leadData.first_name == null ||
                leadData.last_name == null ||
                reply.phonem == null ||
                reply.phonem === ''
              "
            >
              <b-row>
                <b-col md="6">
                  <b-form-group
                    id="fieldset-horizontal"
                    label-class="font-bureau-style font-weight-normal color-gray-input-sn"
                    label-cols-sm="4"
                    label-for="input-horizontal"
                    label-cols-lg="6"
                    content-cols-sm
                    content-cols-lg="6"
                    label="Appointment"
                  >
                    <span
                      v-if="
                        leadData.first_name == null ||
                        leadData.last_name == null ||
                        reply.phonem == null ||
                        reply.phonem == ''
                      "
                      class="text-danger"
                      >First name, Last name and phone are required</span
                    >
                  </b-form-group>
                </b-col>
              </b-row>
            </template>
            <template v-else>
              <b-row>
                <b-col md="6">
                  <b-form-group
                    id="fieldset-horizontal"
                    label-class="font-bureau-style font-weight-normal color-gray-input-sn"
                    label-cols-sm="4"
                    label-for="input-horizontal"
                    label-cols-lg="6"
                    content-cols-sm
                    content-cols-lg="6"
                    label="Appointment"
                  >
                    <VueToggles
                      class=""
                      height="31"
                      width="90"
                      checked-text="YES"
                      unchecked-text="NO"
                      checked-bg="#FF6A6A"
                      :dot-color="reply.filterLead ? 'white' : '#FF6A6A'"
                      unchecked-bg="lightgrey"
                      :value="reply.filterLead"
                      :disabled="disableFilter"
                      font-weight="bold"
                      @click="onClickToggleFilter"
                    />
                  </b-form-group>
                </b-col>
                <b-col>
                  <ValidationProvider
                    v-slot="{ errors }"
                    :rules="reply.filterLead === false ? 'required' : ''"
                    name="motive"
                  >
                    <b-form-group
                      v-if="reply.filterLead === false"
                      id="fieldset-horizontal"
                      label="Motive"
                      label-for="st-ad"
                      label-class="font-bureau-style font-weight-normal color-gray-input-sn"
                      label-cols-sm="4"
                      label-cols-lg="4"
                      content-cols-sm
                      content-cols-lg="8"
                      class="m-0"
                      :state="errors[0] ? false : null"
                    >
                      <div
                        class="d-flex justify-content-between align-items-center"
                      >
                        <b-form-select
                          v-model="reply.motiveNoFilter"
                          class="form-control"
                          :state="errors[0] ? false : null"
                          :options="motivesNoFilterOptions"
                          text-field="description"
                          value-field="id"
                        />
                        <feather-icon
                          v-if="isCeo || isSupervisor || isTeamLeader"
                          icon="PlusCircleIcon"
                          class="text-primary cursor-pointer"
                          style="margin-left: 1rem"
                          size="26"
                          @click="openEditMotiveModalOn(3)"
                        />
                      </div>
                      <div v-if="errors[0]" class="text-error-sn">
                        Motive {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
              </b-row>
              <!-- <template v-if="!reply.filterLead">
                <task-add-later :lead="reply" />
              </template> -->
              <template v-if="reply.filterLead">
                <appointment-add
                  v-if="!disableFilter"
                  :lead="reply"
                  :modul="2"
                />
              </template>
            </template>
          </template>
        </ValidationObserver>
      </div>
    </transition>
    <create-edit-motive-modal
      v-if="createEditMotiveModalOn"
      :is-from-reply="true"
      :type-from-reply="motiveTypeReply"
      @close="closeEditMotiveModalOn"
      @reload="getMotiveLossByType"
    />
  </div>
</template>

<script>
import StandarFormGroup from "@/views/social-network/views/leads/components/StandarFormGroup.vue";
import TaskAddLater from "@/views/social-network/views/leads/components/lead-create/task-create-lead-tab/TaskAddLater.vue";
import AppointmentAdd from "@/views/social-network/views/leads/components/lead-create/appointment-create-lead/AppointmentAdd.vue";
import CreateEditMotiveModal from "@/views/social-network/views/settings/modals/CreateEditMotiveModal.vue";
import SettingsService from "@/views/social-network/views/settings/settings.service";
import SNLeadsService from "@/views/social-network/services/leads";
import WithoutLeadsService from "@/views/social-network/views/leads/tabs/without-leads/services/without-leads.service";
import VueToggles from "vue-toggles";
import { mapGetters, mapState } from "vuex";

export default {
  components: {
    StandarFormGroup,
    TaskAddLater,
    AppointmentAdd,
    CreateEditMotiveModal,
    VueToggles,
  },
  props: {
    reply: {
      type: Object,
      required: true,
    },
    personalInfo: {
      type: Object,
      required: true,
    },
    // eslint-disable-next-line vue/require-default-prop
    personalMobile: {
      type: Object,
    },
    isToday: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      motivesNoAnswerOptions: [],
      motivesNoMobileOptions: [],
      motivesNoFilterOptions: [],
      motiveTypeReply: null,
      createEditMotiveModalOn: false,
      typeNoAnswer: 1,
      typeNoMobile: 2,
      typeNoFilter: 3,
      disableDialogue: false,
      leadData: {},
      currentPhone: "",
      disableFilter: false,
      disableMobile: false,
      editPhone: false,
      mobileIsGiven: false,
      dataMotivesReply: {},
      optionsMotive: [
        {
          description: "Select Motive",
          id: null,
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    ...mapState("SocialNetworkLeadsStore", [
      "S_FLYERS",
      "S_ORGANIC_FLYERS",
      "S_PHONE_FLYERS",
      "S_RECOVERY_FLYERS",
    ]),
    isFacebook() {
      return this.parent_source === 1;
    },
    isGoogle() {
      return this.parent_source === 2;
    },
    saveWhenNoHavingEnoughInformation() {
      return (
        this.reply.addEvidence === true &&
        this.reply.addEvidenceMobile === true &&
        (!this.leadData.first_name ||
          !this.leadData.last_name ||
          !this.reply.phonem) &&
        this.reply.motiveNoFilter == null
      );
    },
    saveWhenAllStepsAreDone() {
      return (
        this.reply.addEvidence === true &&
        this.reply.addEvidenceMobile === true &&
        this.reply.filterLead === true &&
        this.reply.motiveNoFilter == null
      );
    },
  },
  async created() {
    await this.getMotiveLossByType();
    await this.setUpToggles();
    this.isMobile();
    this.currentPhone = this.reply.phonem;
    this.show = true;
  },
  methods: {
    blockAll() {
      this.$set(this.reply, "addEvidence", true);
      this.$set(this.reply, "addEvidenceMobile", true);
      this.$set(this.reply, "filterLead", true);
      this.disableDialogue = true;
      this.disableMobile = true;
      this.disableFilter = true;
      this.editPhone = true;
    },
    openEditMotiveModalOn(type) {
      this.motiveTypeReply = type;
      this.createEditMotiveModalOn = true;
    },
    closeEditMotiveModalOn() {
      this.createEditMotiveModalOn = false;
    },
    onClickToggleAnswer() {
      this.$set(this.reply, "addEvidence", !this.reply.addEvidence);
      // this.reply.addEvidence = !this.reply.addEvidence;
      if (!this.mobileIsGiven) {
        // this.reply.addEvidenceMobile = false;
      }
      this.$set(this.reply, "filterLead", false);
      this.reply.motiveNoAnswer = null;
      this.reply.motiveNoMobile = null;
      this.reply.motiveNoFilter = null;
    },
    onClickTogglePhone() {
      this.$set(this.reply, "addEvidence", true);
      this.$set(this.reply, "addEvidenceMobile", !this.reply.addEvidenceMobile);
      this.$set(this.reply, "filterLead", false);
      this.reply.motiveNoAnswer = null;
      this.reply.motiveNoMobile = null;
      this.reply.motiveNoFilter = null;
    },
    onClickToggleFilter() {
      this.$set(this.reply, "addEvidence", true);
      this.$set(this.reply, "addEvidenceMobile", true);
      this.$set(this.reply, "filterLead", !this.reply.filterLead);
      this.reply.motiveNoAnswer = null;
      this.reply.motiveNoMobile = null;
      this.reply.motiveNoFilter = null;
    },
    async setUpToggles() {
      const lead = await SNLeadsService.getLeadById({
        leadId: this.$route.params.id,
      });
      if (lead.status === 200) {
        this.leadData = lead.data[0];
      }
      const data = await SNLeadsService.getReplyMotivesById({
        replyId: this.reply.reply_id,
      });
      if (data.status === 200) {
        if (data.data.length === 0) {
          // this.blockAll();
        } else {
          this.dataMotivesReply = data.data[0];
          if (this.dataMotivesReply.deleted_at !== null) {
            this.blockAll();
          } else if (this.dataMotivesReply.type_id === 1) {
            this.reply.motiveNoAnswer = this.dataMotivesReply.motive_id;
            this.reply.addEvidence = false;
          } else if (this.dataMotivesReply.type_id === 2) {
            this.reply.motiveNoMobile = this.dataMotivesReply.motive_id;
            this.reply.addEvidence = true;
            this.reply.addEvidenceMobile = false;
          } else if (this.dataMotivesReply.type_id === 3) {
            this.reply.motiveNoFilter = this.dataMotivesReply.motive_id;
            this.reply.addEvidence = true;
            this.reply.addEvidenceMobile = true;
            this.reply.filterLead = false;
          }
        }
      }
    },
    isMobile() {
      this.reply.phonem = this.personalMobile.phonem;
      if (this.reply.phonem) {
        this.mobileIsGiven = true;
        this.disableMobile = true;
        this.reply.addEvidenceMobile = true;
      }
    },
    async getMotiveLossByType() {
      const data = await SettingsService.getMotiveLossByType({
        type_id: this.typeNoAnswer,
      });
      if (data.status === 200) {
        this.reply.motiveNoAnswer = null;
        this.motivesNoAnswerOptions = [...this.optionsMotive, ...data.data];
      }
      const result = await SettingsService.getMotiveLossByType({
        type_id: this.typeNoMobile,
      });
      if (result.status === 200) {
        this.reply.motiveNoMobile = null;
        this.motivesNoMobileOptions = [...this.optionsMotive, ...result.data];
      }

      const dataMotive = await SettingsService.getMotiveLossByType({
        type_id: this.typeNoFilter,
      });
      if (dataMotive.status === 200) {
        this.reply.motiveNoFilter = null;
        this.motivesNoFilterOptions = [
          ...this.optionsMotive,
          ...dataMotive.data,
        ];
      }
    },
    async validateMobile(mobile) {
      if (mobile.length === 14) {
        this.$set(this.reply, 'phone', this.reply.phone)
        try {
          const validate = await SNLeadsService.verifyMobile({
            mobile,
          });

          if (validate.status === 200) {
            if (validate.data.error_code == "MOBILE_EXISTS") {
              const verifyMobile = await this.showConfirmSwal(
                `The phone number already exists: ${validate.data.message}`,
                "",
                {
                  confirmButtonText: "OPEN LEAD",
                }
              );

              if (verifyMobile.value) {
                window.open(
                  `/socialnetwork/leads/new/dashboard/${validate.data.lead_id}`,
                  "_blank"
                );
              }
            }
          }
        } catch (error) {
          this.showErrorSwal(error);
        }
      }else{
        this.$set(this.reply, 'phone', '')
      }
    },
    async saveReply() {
      const validate = await this.$refs.form.validate();

      if (validate) {
        const confirm = await this.showGenericConfirmSwal({});

        if (confirm.isConfirmed) {
          let motiveToSend = null;
          if (this.saveWhenNoHavingEnoughInformation) {
            this.reply.motiveNoFilter = 3;
          }
          if (this.reply.motiveNoFilter !== null) {
            motiveToSend = this.reply.motiveNoFilter;
          } else if (this.reply.motiveNoMobile !== null) {
            motiveToSend = this.reply.motiveNoMobile;
          } else if (this.reply.motiveNoAnswer !== null) {
            motiveToSend = this.reply.motiveNoAnswer;
          }

          if (this.saveWhenAllStepsAreDone) {
            motiveToSend = null;
          }

          const params = {
            replyIdP: this.reply.reply_id,
            motiveIdP: motiveToSend,
            leadIdP: this.reply.id_lead,
            phoneP: this.reply.phonem,
            catcherIdP: this.reply.idCatcher,
            addEvidenceP: this.reply.addEvidence,
            ...this.reply,
            oldReply: this.reply.old_reply == 1
          };

          const response = await SNLeadsService.editReply(params);
          if (response.status === 200) {
            await this.updateChanges(1,this.reply.addEvidenceMobile?1:2);
            this.$emit("updatedMobile");
            this.showToast();
            this.$emit("close", response.data);
            // this.$emit('close')
          }
        }
      }
    },
    async updateChanges(type, value) {
      try {
        const params = {
          type,
          value,
          id_reply: this.reply.reply_id,
          reason_id: this.reply.motiveNoAnswer?this.reply.motiveNoAnswer:this.reply.motiveNoMobile,
          lead_id: this.reply.id_lead,
          user_id: this.currentUser.user_id,
        };

        const response = await SNLeadsService.updatePotentialDialogById(params);

        if (response.status == 200) {
          await this.updateChangesLead(value);
        }
      } catch (error) {
        this.showErrorSwal(error);
        
      }
    },
    async updateChangesLead(value){
      try{
        let params = {
              type: value,
              lead_id: this.reply.id_lead,
              motive: this.reply.motiveNoAnswer?this.reply.motiveNoAnswer:this.reply.motiveNoMobile,
              user_id: this.currentUser.user_id,
            }
            const result = await WithoutLeadsService.updateStatusPotencial(
              params
            )
            if(result.status==200){
              this.showSuccessSwal("Success!", "OPERATION SUCCESSFULLY");
            }
      }catch(error){
        this.showErrorSwal(error);
      }
    },
  },
};
</script>

<style scoped>
</style>
