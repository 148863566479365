<template>
  <div>
    <b-tab
      v-for="item in 3"
      :key="item"
      title-item-class="w-vertical-tab"
      title-link-class="nav-link-vertical nav-link-sn mx-0 border-light"
    >
      <template #title>
        <div>
          <b-skeleton width="100%" style="min-width: 80px"></b-skeleton>
        </div>
      </template>
      <b-row>
        <b-col md="9">
          <b-row class="mt-2">
            <b-col cols="12" md="4">
              <b-skeleton width="85%"></b-skeleton>
              <b-skeleton width="55%"></b-skeleton>
              <b-skeleton width="70%"></b-skeleton>
              <b-skeleton width="90%"></b-skeleton>
            </b-col>
            <b-col cols="12" md="8">
              <b-card class="p-2">
                <b-row class="d-flex justify-content-between" no-gutters>
                  <b-col>
                    <b-skeleton width="70%"></b-skeleton>
                  </b-col>
                  <b-col>
                    <b-skeleton width="70%"></b-skeleton>
                  </b-col>
                </b-row>
                <b-row class="mt-2">
                  <b-skeleton-img no-aspect height="150px"></b-skeleton-img>
                </b-row>
              </b-card>
            </b-col>
          </b-row>
        </b-col>
        <b-col md="3" class="px-4 m-auto h-100">
          <b-skeleton-img></b-skeleton-img>
        </b-col>
        <b-col md="6" class="pr-0">
          <b-card class="px-1">
            <b-skeleton-table
              :rows="2"
              :columns="3"
              :table-props="{ bordered: true, striped: true }"
            ></b-skeleton-table>
          </b-card>
        </b-col>
        <b-col md="6" class="px-1 h-100">
          <b-card class="px-2">
            <b-skeleton-table
              :rows="2"
              :columns="4"
              :table-props="{ bordered: true, striped: true }"
            ></b-skeleton-table>
          </b-card>
        </b-col>
      </b-row>
    </b-tab>
  </div>
</template>
<script>
export default {
  name: "ContactInformationSkeleton",
};
</script>