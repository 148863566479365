<template>
  <div>
    <header-slot>
      <b-row slot="actions" class="justify-content-end">
        <rc-chat-icon v-if="lead.mobile"
          class="mr-1"
          :lead-id="lead.id"
          :lead-phone-number="lead.mobile"
          :lead-name="lead.lead_name"
        />

        <court-info-action :lead-id="lead.id" />

        <lead-create-social color_btn="primary" />
        <b-button
          variant="success"
          class="mr-1"
          :disabled="isResponsibleLead"
          @click="openModalSendSms(lead)"
        >
          <feather-icon
            icon="MessageCircleIcon"
            size="15"
            class="mr-50 text-white"
          />
          SEND SMS
        </b-button>

        <b-button
          variant="outline-secondary"
          class="btn-icon mr-1"
          @click="openModalHistorySms"
        >
          <feather-icon icon="ListIcon" size="18" />
        </b-button>
      </b-row>
    </header-slot>

    <b-row class="card-group">
      <b-col md="12">
        <card-personal-information
          :modul="moduleId"
          :lead="lead"
          :personalInfo="personalInfo"
          v-if="Object.keys(personalInfo).length"
          :personalAddress="personalAddress"
          :personalMobile="personalMobile"
          :requiredFieldsForCreateCrmTask="requiredFieldsForCreateCrmTask"
          :dataAlreadyLoaded="dataAlreadyLoaded"
          :updateMobile="updateMobile"
        />
      </b-col>
      <b-col xl="6" lg="6" md="12" sm="12" cols="12">
        <card-address
          :personalAddress="personalAddress"
          :requiredFieldsForCreateCrmTask="requiredFieldsForCreateCrmTask"
          @cancel-edit-address="cancelEditAddress"
        />
      </b-col>
      <b-col xl="6" lg="6" md="12" sm="12" cols="12">
        <div style="min-height: 300px" class="card">
          <client-call-log
            with-header
            :max-height="'245px'"
            :double-paginate="false"
            min-fields
          ></client-call-log>
        </div>
      </b-col>
      <b-col md="8">
        <card-lead-credit-report :lead="lead" />
      </b-col>
      <b-col md="4">
        <card-lead-cards
          :lead="lead"
          :cardsLead="cardsLead"
          :modul="moduleId"
        />
      </b-col>
      <b-col md="12" v-if="loadLeadStatus">
        <card-contact-information-new
          :catcher="currentUser.user_id"
          :lead="lead"
          :personalInfo="personalInfo"
          :personalMobile="personalMobile"
          :requiredFieldsForCreateCrmTask="requiredFieldsForCreateCrmTask"
          :modul="moduleId"
          @updatedMobile="updatedMobile"
          @onAddContact="onAddContact"
          @onEditReply="onEditReply"
        />
      </b-col>
    </b-row>

    <modal-send-sms
      v-if="showModalSendSms"
      :smss="leads_sms"
      :modul="currentUser.modul_id"
      :typesms="typesms"
      :sms="leads_sms_o"
      :name-leads="name_leads_arr[0]"
      @hide="closeModalSendSms"
    />

    <b-modal
      id="modal-history-sms"
      ok-only
      modal-class="modal-primary"
      title-class="text-white h4"
      centered
      size="lg"
      title="History of Sms"
      hide-footer
    >
      <modal-history-sms
        :id="historySms.id"
        :modul="currentUser.modul_id"
        :lead-name="historySms.leadName"
      />
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

// Components
import CardPersonalInformation from "./components/personal-information/CardPersonalInformation.vue";
import CardAddress from "./components/address/CardAddress.vue";
import CardCreditReport from "./components/credit-report/CardCreditReport.vue";
import CardLeadCreditReport from "@/views/crm/views/Lead/lead-module/dashboard/CardLeadCreditReport.vue";
import CardContactInformation from "./components/contact-information/CardContactInformation.vue";
import CardContactInformationNew from "@/views/social-network/views/leads/tabs/new-leads/views/dashboard/components/contact-information/CardContactInformationNew.vue";
import ModalSendSms from "@/views/crm/views/Lead/lead-sms/ModalSendSms.vue";
import ModalHistorySms from "@/views/crm/views/Lead/lead-sms/ModalHistorySms.vue";
import LeadCreateSocial from "@/views/social-network/views/leads/components/lead-create/LeadCreateSocial";
import ClientCallLog from "@/views/commons/components/ring-central/call-log/components/ClientCallLog.vue";
import CardLeadCards from "./components/cards/CardLeadCards.vue";
// Services
import SNLeadsService from "@/views/social-network/services/leads";
import CourtInfoAction from "@/views/debt-solution/views/court-info/components/CourtInfoAction.vue";
import RcChatIcon from "@/views/commons/components/ring-central/chat-sms/RcChatIcon.vue";

export default {
  components: {
    CardPersonalInformation,
    CardAddress,
    CardCreditReport,
    CardContactInformation,
    ModalSendSms,
    ModalHistorySms,
    LeadCreateSocial,
    CardContactInformationNew,
    ClientCallLog,
    CardLeadCreditReport,
    CardLeadCards,
    CourtInfoAction,
    RcChatIcon
  },
  data() {
    return {
      loadLeadStatus: false,
      updateMobile: false,
      personalInfo: {},
      personalAddress: {},
      cardsLead: {},
      personalMobile: {},
      requiredFieldsForCreateCrmTask: {
        first_name: null,
        last_name: null,
        mobile: null,
        state: null,
      },
      lead: {},
      user: null,
      historySms: {
        leadName: "",
        id: null,
      },
      leads_sms: [],
      typesms: 1,
      leads_sms_o: [],
      name_leads_arr: [],
      showModalSendSms: false,
      dataAlreadyLoaded: false,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    isResponsibleLead() {
      if (this.$route.params.id) {
        return !(this.$store.getters['NotificationStore/G_IS_RESPONSIBLE_CEO'].is_responsible === this.currentUser.user_id || this.$store.getters['NotificationStore/G_IS_RESPONSIBLE_CEO'].is_responsible === null);
      }
      return false;
    },
  },
  methods: {
    isResponsible() {
      this.$store.dispatch('NotificationStore/A_IS_RESPONSIBLE_CEO', { lead_id: this.$route.params.id });
    },
    updatedMobile() {
      this.updateMobile = true;
      setTimeout(() => {
        this.updateMobile = false;
      }, 5000);
    },
    onAddContact() {
      this.getLead();
    },
    onEditReply() {
      this.getLead();
    },
    openModalSendSms(item) {
      this.rowData = item;
      this.leads_sms = [];
      this.typesms = 1;
      this.leads_sms_o = [];
      this.leads_sms_o.push(item.id);
      this.name_leads_arr = [{ name: item.lead_name, id: item.id }];
      this.showModalSendSms = true;
    },
    cancelEditAddress(originalAddress) {
      this.personalAddress = originalAddress;
    },
    closeModalSendSms() {
      this.showModalSendSms = false;
    },
    openModalHistorySms() {
      this.historySms.id = this.lead.id;
      this.historySms.leadName = this.lead.lead_name;
      this.$bvModal.show("modal-history-sms");
    },
    async getLead() {
      try {
        this.addPreloader();
        let idParam = this.$route.params.id;
        const response = await SNLeadsService.getLead(idParam);
        if (response.status == 200) {
          this.lead = response.data[0];
        }
        this.removePreloader();
      } catch (error) {
        this.removePreloader();
        throw error;
      }
    },
    async getPersonalInformation() {
      this.user = this.currentUser;

      let document = this.lead.type_document_sn
        ? this.lead.type_document_sn
        : this.lead.ssn
        ? 1
        : this.lead.itin
        ? 2
        : this.lead.other
        ? 3
        : null;

      let numberDocument =
        document == 1
          ? this.lead.ssn
          : document == 2
          ? this.lead.itin
          : document == 3
          ? this.lead.other
          : null;

      let numberDocumentEnc =
        document == 1
          ? this.lead.ssn_enc
          : document == 2
          ? this.lead.itin_enc
          : document == 3
          ? this.lead.other_enc
          : null;

      // Personal information
      this.personalInfo = {
        id_lead: this.lead.id,
        valueStatus: this.lead.valuestatuslead,
        nickname: this.lead.nickname,
        name: this.lead.lfirst_name,
        status: this.lead.status_l,
        last_name: this.lead.last_name,
        stateAd: this.lead.state,
        document: document,
        numberDocument: numberDocument,
        numberDocumentEnc: numberDocumentEnc,
        ssn: this.lead.ssn,
        itin: this.lead.itin,
        other: this.lead.other,
        dob: this.lead.dob,
        origin_country: this.lead.origin_country,
        email: this.lead.email,
        language: this.lead.language ? this.lead.language.toUpperCase() : null,
        id_user: this.user.user_id,
      };

      // Personal mobile
      this.personalMobile = {
        id_lead: this.lead.id,
        phonem: this.lead.mobile,
        id_user: this.user.user_id,
        typee: 3,
        module_id: this.currentUser.modul_id,
      };

      // Required field for create task in CRM
      this.requiredFieldsForCreateCrmTask.first_name = this.personalInfo.name;
      this.requiredFieldsForCreateCrmTask.last_name =
        this.personalInfo.last_name;
      this.requiredFieldsForCreateCrmTask.mobile = this.personalMobile.phonem;

      // Personal address
      this.personalAddress = {
        id_lead: this.lead.id,
        street: this.lead.street,
        city: this.lead.city,
        zipcode: this.lead.zipcode,
        state: this.lead.state_slug,
        country: this.lead.country ? this.lead.country : "United States",
        id_user: this.user.user_id,
        typee: 1,
      };
      this.dataAlreadyLoaded = true;
    },
    getCardsLead() {
      this.cardsLead = {
        lead_id: this.lead.id,
        user_id: this.user.user_id,
        rol: this.user.role_id,
      };
    },
  },
  async created() {
    this.isResponsible();
    await this.getLead();
    await this.getPersonalInformation();
    this.getCardsLead();
    this.loadLeadStatus = true;
  },
};
</script>

<style lang="scss" scoped>
.card-group > div > .card > .card-header {
  border-bottom: none !important;
  margin-bottom: 0px !important;
}
</style>
