<template>
  <div>
    <v-select
      id="vSelectReasons"
      v-model="selectedReason"
      :options="arrayReasons"
      label="reason"
      class="w-100"
      transition
    />
  </div>
</template>

<script>
import vSelect from "vue-select";

// Services
import SNLeadsService from "@/views/social-network/services/leads";

export default {
  props:{
    reason:{
      type: Number,
      required: false,
      default: 6
    }
  },
  async mounted() {
    await this.getReasonsNoPotential();
  },
  components: {
    vSelect,
  },
  data() {
    return {
      arrayReasons: [],
      selectedReason: null,
    };
  },
  methods: {
    async getReasonsNoPotential() {
      try {
        const response = await SNLeadsService.getReasonsNotPotential({type_reason: this.reason});

        if (response.status == 200) {
          this.arrayReasons = response.data;
        }
      } catch (error) {
        throw error;
      }
    },
  },
  watch: {
    selectedReason(newVal) {
      this.$emit("input", newVal);
    },
  },
};
</script>

<style>
</style>
