var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{attrs:{"body-class":"px-0"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('b-card-title',{staticClass:"card-title-address"},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('div',[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Contact Information")]),_c('b-button',{staticClass:"btn-icon position-plus",attrs:{"size":"sm","title":'Add new contact',"variant":"primary","disabled":_vm.isResponsibleLead},on:{"click":function($event){return _vm.openModalAddContact()}}},[_c('feather-icon',{attrs:{"icon":"PlusIcon"}})],1)],1)])])]},proxy:true}])},[_c('div',{staticClass:"p-relative",style:({
      marginTop: _vm.tabInfo.length > 0 ? '-15px' : '0px',
      height: _vm.tabInfo.length > 0 ? 'auto' : '100%',
      display: _vm.tabInfo.length > 0 ? 'block' : 'flex',
      justifyContent: _vm.tabInfo.length > 0 ? 'unset' : 'center',
      alignItems: _vm.tabInfo.length > 0 ? 'unset' : 'center',
    })},[(_vm.tabInfo.length > 0)?_c('div',[_c('p',{staticClass:"font-weight-bold text-left ml-2 my-0 pt-0 mb-1 mt-1"},[_vm._v(" Sources ")]),_c('b-tabs',{attrs:{"id":"ci-source-tabs","pills":"","active-nav-item-class":"bg-primary box-shadow-primary border-primary primary","nav-class":"border-bottom-vertical-tab pb-1 rounded-0","lazy":""},on:{"input":_vm.changeSource},model:{value:(_vm.sourceIndex),callback:function ($$v) {_vm.sourceIndex=$$v},expression:"sourceIndex"}},[_c('p',{staticClass:"font-weight-bold text-left ml-2 my-0 pt-0 mb-1"},[_vm._v(" Contact method ")]),_vm._l((_vm.tabInfo),function(source,index){return _c('b-tab',{key:index + '_' + source.source_id,attrs:{"lazy":"","title-link-class":"sub-tab px-3 ml-2"},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"title":source.old_lead === 0 ? 'New Source' : 'Old Source'}},[_vm._v(_vm._s(source.name))])]},proxy:true}],null,true)},[_c('b-tabs',{attrs:{"id":"ci-subsource-tabs","pills":"","active-nav-item-class":"bg-orange-tab","content-class":"padding-vertical-tab","nav-class":"border-bottom-vertical-tab nav-pill-warning  pb-1 rounded-0 ","lazy":""},on:{"input":_vm.changeContactMethod},model:{value:(_vm.contactMethodIndex),callback:function ($$v) {_vm.contactMethodIndex=$$v},expression:"contactMethodIndex"}},_vm._l((source.contact_method),function(contact_method,index){return _c('b-tab',{key:index + '_' + source.contact_method_id,attrs:{"title-item-class":"text-center ml-3","title-link-class":"bg-warning-tab"},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"title":contact_method.old_lead === 0
                      ? 'New Contact Method'
                      : 'Old Contact Method'}},[_vm._v(_vm._s(contact_method.name))])]},proxy:true}],null,true)},[_c('div',{staticClass:"ml-3"},[_c('b-tabs',{attrs:{"id":"ci-vertical-tabs","active-nav-item-class":"font-weight-bold text-uppercase nav-link-sn-active","nav-class":"border-right-vertical-tab border-bottom-0 h-100 rounded-0 ","lazy":"","vertical":""},model:{value:(_vm.replyIndex),callback:function ($$v) {_vm.replyIndex=$$v},expression:"replyIndex"}},[(_vm.showContactInformation)?_c('div',_vm._l((contact_method.replies),function(reply,index){return _c('b-tab',{key:index + '_' + reply.reply_id,attrs:{"title-item-class":"w-vertical-tab","title-link-class":"nav-link-vertical nav-link-sn mx-0 border-light"},on:{"click":function($event){return _vm.setActiveColor(index)}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',{class:[
                            'vertical-item',
                            _vm.activeReply == index
                              ? 'vertical-item-active'
                              : '' ]},[(contact_method.old_lead === 0)?_c('span',[_vm._v(" "+_vm._s(reply.name_replie .replace("CONTACT", "") .replace("RECOVERY", "")))]):_c('span',[_vm._v(" "+_vm._s(reply.count_reply)+" ")])]),(contact_method.old_lead === 0)?_c('span',[_vm._v(" "+_vm._s(reply.type_reply == "REPLY" ? "CONTACT" : reply.type_reply)+" ")]):_c('span',[_vm._v(" "+_vm._s(reply.type_reply)+" ")]),(_vm.dateNow <= reply.created_at_time)?_c('feather-icon',{staticStyle:{"margin-left":"0.5rem"},attrs:{"icon":"Edit2Icon","size":"17"},on:{"click":function($event){return _vm.editReply(reply)}}}):_vm._e()]},proxy:true}],null,true)},[_c('b-row',[_c('b-col',{attrs:{"md":"9"}},[_c('b-row',{staticStyle:{"padding-top":"37px"}},[_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('contact-information',{ref:"contactInformation",refInFor:true,attrs:{"contact-info":{
                                  catcher: reply.name_catcher,
                                  potential: reply.potencial,
                                  program: reply.fanpage,
                                  date: reply.created_at,
                                  dialogue: reply.dialogue,
                                  organic: reply.organic,
                                  reply_id: reply.reply_id,
                                  contact_method: reply.contact_method,
                                  reason: reply.reason,
                                  reason_other: reply.reason_other,
                                },"reply":reply,"parent_source":source.id},on:{"updateOthers":function($event){reply.reason_other = $event},"updatePotential":function($event){reply.potencial = $event}}})],1),_c('b-col',{attrs:{"cols":"12","md":"8"}},[_c('contact-notes',{attrs:{"reply":reply,"personal-info":_vm.personalInfo,"lead":_vm.lead,"notes":{ text: 58 }}})],1)],1)],1),_c('b-col',{directives:[{name:"show",rawName:"v-show",value:(source.id == 1),expression:"source.id == 1"}],staticClass:"d-flex flex-column justify-content-between m-auto",attrs:{"md":"3"}},[_c('contact-flyer',{attrs:{"fyer-reply":{
                              flyer_id: reply.flyer_id,
                              reply_id: reply.reply_id,
                              route: reply.flyer_route,
                              stAD: _vm.personalInfo.stateAd,
                              fanpage_id: reply.fanpage_id,
                              fanpage_name: reply.fanpage,
                              flyer_state: reply.flyer_state,
                            },"reply":reply}})],1),_c('b-col',{attrs:{"md":"6"}},[_c('contact-tasks',{attrs:{"reply-id":reply.reply_id,"required-fields-for-create-crm-task":_vm.requiredFieldsForCreateCrmTask,"modul":_vm.modul,"lead":_vm.lead}})],1),_c('b-col',{staticClass:"h-100",attrs:{"md":"6"}},[_c('card-lead-appointment',{staticClass:"border border-light rounded mb-0 h-100 mr-2",staticStyle:{"height":"360px !important"},attrs:{"onlyRead":false,"isBusy":false,"lead":_vm.lead}})],1)],1)],1)}),1):_c('div',{staticStyle:{"min-height":"50vh"}},[_c('ContactInformationSkeleton')],1)])],1)])}),1)],1)})],2)],1):_c('div',[_c('div',{staticClass:"text-center"},[_c('feather-icon',{staticClass:"text-warning",attrs:{"icon":"AlertTriangleIcon","size":"30"}}),_c('p',{staticClass:"text-warning"},[_vm._v("No data")])],1)])]),(_vm.showModalAddContact)?_c('modal-add-contact',{attrs:{"reply":_vm.replyData,"lead":_vm.lead,"personal-mobile":_vm.personalMobile,"personal-info":_vm.personalInfo},on:{"updatedMobile":_vm.updatedMobile,"onClose":_vm.closeModalAddContact,"onAddContact":_vm.onAddContact}}):_vm._e(),(_vm.showModalEditContact)?_c('modal-edit-contact',{attrs:{"contact":_vm.contact,"personal-mobile":_vm.personalMobile,"personal-info-prop":_vm.personalInfo},on:{"updatedMobile":_vm.updatedMobile,"close":_vm.closeModalAddContact,"onEditReply":_vm.onEditReply}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }