<template>
  <b-card body-class="px-0">
    <template #header>
      <b-card-title class="card-title-address">
        <div class="d-flex justify-content-between">
          <div>
            <span class="font-weight-bold">Contact Information</span>
            <b-button
              size="sm"
              :title="'Add new contact'"
              variant="primary"
              class="btn-icon position-plus"
              :disabled="isResponsibleLead"
              @click="openModalAddContact()"
            >
              <feather-icon icon="PlusIcon" />
            </b-button>
          </div>
        </div>
      </b-card-title>
    </template>

    <div
      class="p-relative"
      :style="{
        marginTop: tabInfo.length > 0 ? '-15px' : '0px',
        height: tabInfo.length > 0 ? 'auto' : '100%',
        display: tabInfo.length > 0 ? 'block' : 'flex',
        justifyContent: tabInfo.length > 0 ? 'unset' : 'center',
        alignItems: tabInfo.length > 0 ? 'unset' : 'center',
      }"
    >
      <div v-if="tabInfo.length > 0">
        <p class="font-weight-bold text-left ml-2 my-0 pt-0 mb-1 mt-1">
          Sources
        </p>
        <b-tabs
          id="ci-source-tabs"
          v-model="sourceIndex"
          pills
          active-nav-item-class="bg-primary box-shadow-primary border-primary primary"
          nav-class="border-bottom-vertical-tab pb-1 rounded-0"
          lazy
          @input="changeSource"
        >
          <p class="font-weight-bold text-left ml-2 my-0 pt-0 mb-1">
            Contact method
          </p>
          <b-tab
            v-for="(source, index) in tabInfo"
            :key="index + '_' + source.source_id"
            lazy
            title-link-class="sub-tab px-3 ml-2"
          >
            <template #title>
              <span
                v-b-tooltip.hover
                :title="source.old_lead === 0 ? 'New Source' : 'Old Source'"
                >{{ source.name }}</span
              >
            </template>

            <b-tabs
              id="ci-subsource-tabs"
              v-model="contactMethodIndex"
              @input="changeContactMethod"
              pills
              active-nav-item-class="bg-orange-tab"
              content-class="padding-vertical-tab"
              nav-class="border-bottom-vertical-tab nav-pill-warning  pb-1 rounded-0 "
              lazy
            >
              <b-tab
                v-for="(contact_method, index) in source.contact_method"
                :key="index + '_' + source.contact_method_id"
                title-item-class="text-center ml-3"
                title-link-class="bg-warning-tab"
              >
                <template #title>
                  <!-- <span>{{ contact_method.name }}</span> -->
                  <span
                    v-b-tooltip.hover
                    :title="
                      contact_method.old_lead === 0
                        ? 'New Contact Method'
                        : 'Old Contact Method'
                    "
                    >{{ contact_method.name }}</span
                  >
                </template>
                <div class="ml-3">
                  <b-tabs
                    id="ci-vertical-tabs"
                    v-model="replyIndex"
                    active-nav-item-class="font-weight-bold text-uppercase nav-link-sn-active"
                    nav-class="border-right-vertical-tab border-bottom-0 h-100 rounded-0 "
                    lazy
                    vertical
                  >
                    <div v-if="showContactInformation">
                      <b-tab
                        @click="setActiveColor(index)"
                        v-for="(reply, index) in contact_method.replies"
                        :key="index + '_' + reply.reply_id"
                        title-item-class="w-vertical-tab"
                        title-link-class="nav-link-vertical nav-link-sn mx-0 border-light"
                      >
                        <template #title>
                          <div
                            :class="[
                              'vertical-item',
                              activeReply == index
                                ? 'vertical-item-active'
                                : '',
                            ]"
                          >
                            <span v-if="contact_method.old_lead === 0">
                              {{
                                reply.name_replie
                                  .replace("CONTACT", "")
                                  .replace("RECOVERY", "")
                              }}</span
                            >
                            <span v-else>
                              {{ reply.count_reply }}
                            </span>
                          </div>
                          <span v-if="contact_method.old_lead === 0">
                            {{
                              reply.type_reply == "REPLY"
                                ? "CONTACT"
                                : reply.type_reply
                            }}
                          </span>
                          <span v-else>
                            {{ reply.type_reply }}
                          </span>
                          <feather-icon
                            v-if="dateNow <= reply.created_at_time"
                            icon="Edit2Icon"
                            style="margin-left: 0.5rem"
                            size="17"
                            @click="editReply(reply)"
                          />
                        </template>
                        <b-row>
                          <b-col md="9">
                            <b-row style="padding-top: 37px">
                              <b-col cols="12" md="4">
                                <contact-information
                                  ref="contactInformation"
                                  :contact-info="{
                                    catcher: reply.name_catcher,
                                    potential: reply.potencial,
                                    program: reply.fanpage,
                                    date: reply.created_at,
                                    dialogue: reply.dialogue,
                                    organic: reply.organic,
                                    reply_id: reply.reply_id,
                                    contact_method: reply.contact_method,
                                    reason: reply.reason,
                                    reason_other: reply.reason_other,
                                  }"
                                  :reply="reply"
                                  :parent_source="source.id"
                                  @updateOthers="reply.reason_other = $event"
                                  @updatePotential="reply.potencial = $event"
                                />
                              </b-col>
                              <b-col cols="12" md="8">
                                <contact-notes
                                  :reply="reply"
                                  :personal-info="personalInfo"
                                  :lead="lead"
                                  :notes="{ text: 58 }"
                                />
                              </b-col>
                            </b-row>
                          </b-col>
                          <b-col
                            v-show="source.id == 1"
                            md="3"
                            class="d-flex flex-column justify-content-between m-auto"
                          >
                            <contact-flyer
                              :fyer-reply="{
                                flyer_id: reply.flyer_id,
                                reply_id: reply.reply_id,
                                route: reply.flyer_route,
                                stAD: personalInfo.stateAd,
                                fanpage_id: reply.fanpage_id,
                                fanpage_name: reply.fanpage,
                                flyer_state: reply.flyer_state,
                              }"
                              :reply="reply"
                            />
                          </b-col>
                          <b-col md="6">
                            <contact-tasks
                              :reply-id="reply.reply_id"
                              :required-fields-for-create-crm-task="
                                requiredFieldsForCreateCrmTask
                              "
                              :modul="modul"
                              :lead="lead"
                            />
                          </b-col>
                          <b-col md="6" class="h-100">
                            <card-lead-appointment
                              class="border border-light rounded mb-0 h-100 mr-2"
                              :onlyRead="false"
                              style="height: 360px !important"
                              :isBusy="false"
                              :lead="lead"
                            />
                          </b-col>
                        </b-row>
                      </b-tab>
                    </div>

                    <div v-else style="min-height: 50vh">
                      <ContactInformationSkeleton />
                    </div>
                  </b-tabs>
                </div>
              </b-tab>
            </b-tabs>
          </b-tab>
        </b-tabs>
      </div>
      <div v-else>
        <div class="text-center">
          <feather-icon
            icon="AlertTriangleIcon"
            class="text-warning"
            size="30"
          />
          <p class="text-warning">No data</p>
        </div>
      </div>
    </div>

    <modal-add-contact
      v-if="showModalAddContact"
      :reply="replyData"
      :lead="lead"
      :personal-mobile="personalMobile"
      :personal-info="personalInfo"
      @updatedMobile="updatedMobile"
      @onClose="closeModalAddContact"
      @onAddContact="onAddContact"
    />
    <modal-edit-contact
      v-if="showModalEditContact"
      :contact="contact"
      :personal-mobile="personalMobile"
      :personal-info-prop="personalInfo"
      @updatedMobile="updatedMobile"
      @close="closeModalAddContact"
      @onEditReply="onEditReply"
    />
  </b-card>
</template>

<script>
// Components
import { mapGetters } from "vuex";
import ModalEditContact from "@/views/social-network/views/leads/tabs/new-leads/views/dashboard/components/contact-information/ModalEditContact.vue";
import SNLeadsService from "@/views/social-network/services/leads";
import ContactInformation from "@/views/social-network/views/leads/tabs/new-leads/views/dashboard/components/contact-information/ContactInformation.vue";
import ContactNotes from "@/views/social-network/views/leads/tabs/new-leads/views/dashboard/components/contact-information/contact-notes/ContactNotes.vue";
import ContactFlyer from "@/views/social-network/views/leads/tabs/new-leads/views/dashboard/components/contact-information/ContactFlyer.vue";
import ContactTasks from "@/views/social-network/views/leads/tabs/new-leads/views/dashboard/components/contact-information/ContactTasks.vue";
import CardLeadAppointment from "@/views/crm/views/Lead/lead-module/dashboard/CardLeadAppointment.vue";
import ModalAddContact from "@/views/social-network/views/leads/tabs/new-leads/views/dashboard/components/contact-information/ModalAddContact.vue";
import ContactInformationSkeleton from "@/views/social-network/views/leads/tabs/new-leads/views/dashboard/components/contact-information/ContactInformationSkeleton.vue";
// Services
import moment from "moment";
export default {
  name: "ContactInformationNew",
  components: {
    ContactInformation,
    ContactNotes,
    ContactFlyer,
    ContactTasks,
    CardLeadAppointment,
    ModalAddContact,
    ModalEditContact,
    ContactInformationSkeleton,
  },
  props: {
    personalInfo: {
      type: Object,
      default: () => ({}),
    },
    requiredFieldsForCreateCrmTask: {
      type: Object,
      default: () => ({}),
    },
    catcher: {
      type: Number,
      default: () => 1,
    },
    lead: {
      type: Object,
    },
    modul: [String, Number],
    personalMobile: {
      type: Object,
    },
  },
  data() {
    return {
      showContactInformation: false,
      currentSourceId: null,
      currentContactMethodId: null,
      showModalEditContact: false,
      replyData: {
        typeReply: null,
        idSubSource: null,
        id_lead: "",
        idCatcher: this.catcher,
        idProgram: null,
        idMethod: null,
        motiveNoAnswer: null,
        motiveNoMobile: null,
        motiveNoFilter: null,
        addEvidence: true,
        addEvidenceMobile: true,
        phonem: null,
        is_organic: false,
        filterLead: false,
        // Task
        title: "",
        flyerId: null,
        seller: null,
        subject: "CALL",
        hour: "",
        from: "",
        to: "",
        date: "",
        sms_status: false,
        due_date: "",
        assign: null,
        attend: null,
        // appointment
        appTitle: "",
        appSeller: null,
        location: "",
        appDescription: "",
        appDate: "",
        appFrom: "",
        appTo: "",
        created_users: null,
        appType: "event",
        dateSp: null,
        dateEn: null,
        hourMsn: null,
        state_h: "CA",
        multiply: 1,
      },
      indexSubSource: 0,
      tabInfo: [],
      showModalAddContact: false,
      sourceIndex: 0,
      contactMethodIndex: 0,
      replyIndex: 0,
      activeReply: 0,
      contact: "",
      validationEditReplay: true,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    leadId() {
      return this.$route.params.id;
    },
    sourceId() {
      return this.tabInfo[this.sourceIndex]?.source_id;
    },
    contactMethodId() {
      return this.tabInfo[this.sourceIndex]?.contact_method[
        this.contactMethodIndex
      ].contact_method_id;
    },
    oldLead() {
      return this.tabInfo[this.sourceIndex]?.contact_method[
        this.contactMethodIndex
      ].old_lead;
    },
    dateNow() {
      return moment().format("YYYY-MM-DD");
    },
    isResponsibleLead() {
      if (this.$route.params.id) {
        return !(
          this.$store.getters["NotificationStore/G_IS_RESPONSIBLE_CEO"]
            .is_responsible === this.currentUser.user_id ||
          this.$store.getters["NotificationStore/G_IS_RESPONSIBLE_CEO"]
            .is_responsible === null
        );
      }
      return false;
    },
  },
  created() {
    this.$nextTick(() => {
      this.getSourcesAndContactMethod();
    });
  },
  mounted() {
    setTimeout(() => {
      this.getReplies();
      this.replyData.id_lead = this.lead.id;
    }, 5000);
  },
  methods: {
    onAddContact() {
      this.$emit("onAddContact");
    },
    onEditReply() {
      this.$emit("onEditReply");
    },
    findObjectIndexByTwoProperty(array, property, value, property2, value2) {
      for (let i = 0; i < array.length; i++) {
        if (array[i][property] === value && array[i][property2] === value2) {
          return i;
        }
      }
      return -1; // return -1 if the object is not found in the array
    },

    async getSourcesAndContactMethod() {
      return new Promise(async (resolve, reject) => {
        try {
          const { data } = await SNLeadsService.getSourceAndContactMethods({
            lead_id: this.leadId,
          });

          this.tabInfo = data;
          // parse all contact_method
          this.tabInfo.forEach((source) => {
            source.contact_method = JSON.parse(source.contact_method);
            source.contact_method.forEach((contact_method) => {
              contact_method.replies = [];
            });
          });
          resolve();
        } catch (error) {
          this.showErrorSwal(error);
          reject(error);
        }
      });
    },
    async getReplies() {
      if (!this.sourceId || !this.contactMethodId) return;
      try {
        const { data } = await SNLeadsService.getReplies({
          id_lead: this.leadId,
          source_id: this.sourceId,
          contact_method_id: this.contactMethodId,
          oldLead: this.oldLead,
        });
        const sourceIndex = this.findObjectIndexByTwoProperty(
          this.tabInfo,
          "source_id",
          this.sourceId,
          "old_lead",
          this.oldLead
        );

        const contactMethodIndex = this.findObjectIndexByTwoProperty(
          this.tabInfo[sourceIndex].contact_method,
          "contact_method_id",
          this.contactMethodId,
          "old_lead",
          this.oldLead
        );

        this.$set(
          this.tabInfo[sourceIndex].contact_method[contactMethodIndex],
          "replies",
          data
        );
        this.$forceUpdate();
        this.$nextTick(() => {
          this.showContactInformation = true;
        });
      } catch (error) {
        throw error;
      }finally {
        this.showContactInformation = true;
      }
    },

    async changeSource(sourceIndex) {
      this.showContactInformation = false;
      this.currentSourceId = this.tabInfo[sourceIndex].source_id;
      this.currentContactMethodId =
        this.tabInfo[sourceIndex].contact_method[0].contact_method_id;
      await this.getReplies();
    },

    async changeContactMethod(contactMethodIndex) {
      this.currentContactMethodId =
        this.tabInfo[this.sourceIndex].contact_method[
          contactMethodIndex
        ].contact_method_id;
      await this.getReplies();
    },
    setActiveColor(index) {
      this.activeReply = index;
    },
    async updatedMobile() {
      await this.getSourcesAndContactMethod();
      this.$emit("updatedMobile");
      this.getReplies();
    },
    closeModalEditContact() {
      this.showModalEditContact = false;
    },
    openModalAddContact(type) {
      this.replyData.type = type;
      this.showModalAddContact = true;
    },
    async closeModalAddContact(response) {
      try {
        this.showModalAddContact = false;
        this.showModalEditContact = false;
      } catch (e) {
        console.error(e);
      }
    },
    editReply(reply) {
      this.showModalEditContact = true;
      this.contact = { ...reply, ...this.replyData };
    },
  },
};
</script>

<style lang="scss">
.fs-18 {
  font-size: 18px;
}

.position-plus {
  position: absolute;
  right: 2rem;
}

#ci-source-tabs {
  .nav-item .nav-link {
    border-radius: 6px !important;
  }

  .border-bottom-vertical-tab {
    border-bottom: 1px rgb(223, 223, 223) solid !important;
  }
}

#ci-subsource-tabs {
  .border-bottom-vertical-tab {
    border-bottom: 1px rgb(223, 223, 223) solid !important;
  }

  .nav-item .nav-link {
    border-radius: 6px !important;
  }
}

#ci-vertical-tabs {
  .nav {
    align-items: flex-start;
  }

  .border-right-vertical-tab {
    border-right: 1px rgb(223, 223, 223) solid !important;
  }

  .nav-item {
    .nav-link {
      margin-bottom: 20px;
      border: none !important;
    }
  }

  .nav-item .nav-link::after {
    background: white !important;
  }

  .nav-item .nav-link.active {
    color: #ff6045 !important;
  }
}

#sn-d-ci-tasks {
  .nav-item {
    .nav-link {
      margin-bottom: 0px;
      border: none !important;
    }
  }

  .nav-item .nav-link.active {
    color: white !important;
  }
}

.vertical-item {
  width: 21px;
  height: 21px;
  background: rgb(226, 217, 217);
  font-size: 15px !important;
  display: flex;
  justify-content: center;
  align-content: center;
  border-radius: 5px;
  margin-right: 10px;
  color: grey !important;

  &-active {
    background: #ff6045 !important;
    color: white !important;
    font-size: 15px !important;
  }
}
</style>
