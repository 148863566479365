<template>
  <div>
    <div class="row mr-1">
      <div class="col-lg-12">
        <div>
          <template v-if="fyerReply.flyer_id">
            <template>
              <!-- Flyer -->
              <div style="position: relative; min-height: 200px; height: 100%">
                <b-img-lazy
                  :src="fyerReply.route"
                  style="width: 100%; height: auto"
                  v-bind="mainProps"
                  :alt="fyerReply.flyer_name"
                >
                </b-img-lazy>
                <!-- Top Badge of flyer -->
                <b-badge
                  variant="info"
                  title="Flyer state"
                  pill
                  class="badge-position badge state-badge"
                  :class="flyerState"
                  >{{ fyerReply.flyer_state }}
                </b-badge>
              </div>
            </template>
          </template>
          <div class="p-relative" v-if="!fyerReply.flyer_id">
            <b-avatar rounded size="22rem">
              <template #default>
                <span class="b-avatar-text" style="font-size: calc(2.4rem)">
                  UNKNOWN
                </span>
              </template>
            </b-avatar>
          </div>
        </div>
      </div>
    </div>

    <meta-ads-modal
      v-if="modalChooseFlyer"
      :program-id="programId"
      :selected-flyer="[{ id: fyerReply.flyer_id }]"
      @selectFlyer="closeModalChooseFlyer($event)"
      @hidden="modalChooseFlyer = false"
    />
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";

// Components
import MetaAdsModal from "@/views/creative/views/meta/components/MetaAdsModal.vue";

export default {
  props: {
    fyerReply: {
      type: Object,
      default: () => ({}),
    },
    reply: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {
    MetaAdsModal,
  },
  data() {
    return {
      //flyers
      editFlyer: false,
      isSpecialFlyer: false,
      modalChooseFlyer: false,
      flyers: [],
      unknown: false,
      titleModalChoose: "",
      imgFanpage: "",
      initialFlyer: true,
      returnFlyer: null,
      returnFlyerImage: null,
      //lazy Img
      mainProps: {
        center: false,
        fluidGrow: true,
        blank: true,
        blankColor: "#bbb",
        height: 364,
        block: false,
        fluid: false,
        rounded: true,
        thumbnail: true,
      },
      blankImgProps: {
        blank: true,
        width: 364,
      },
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    flyerState() {
      if (this.fyerReply.flyer_state == "CA") {
        return "badge-primary";
      } else if (this.fyerReply.flyer_state == "TX") {
        return "badge-success";
      } else {
        return "badge-dark";
      }
    },
    flyerStateNew() {
      if (this.flyers[0].state == "CA") {
        return "badge-primary";
      } else if (this.flyers[0].state == "TX") {
        return "badge-success";
      } else {
        return "badge-dark";
      }
    },
    ...mapState("SocialNetworkLeadsStore", ["S_SPECIAL_FLYERS"]),
    isResponsibleLead() {
      if (this.$route.params.id) {
        return !(
          this.$store.getters["NotificationStore/G_IS_RESPONSIBLE_CEO"]
            .is_responsible === this.currentUser.user_id ||
          this.$store.getters["NotificationStore/G_IS_RESPONSIBLE_CEO"]
            .is_responsible === null
        );
      }
      return false;
    },
  },
  methods: {
    ...mapActions({
      A_GET_FLYERS: "SocialNetworkLeadsStore/A_GET_FLYERS",
      A_UPDATE_FLYER_REPLY: "SocialNetworkLeadsStore/A_UPDATE_FLYER_REPLY",
      A_GET_SPECIAL_FLYERS_ID:
        "SocialNetworkLeadsStore/A_GET_SPECIAL_FLYERS_ID",
    }),
    async openModalChooseFlyer(unknown) {
      this.programId = this.fyerReply.fanpage_id;
      this.modalChooseFlyer = true;
    },
    closeModalChooseFlyer(event) {
      this.modalChooseFlyer = false;
      this.initialFlyer = false;
      this.fyerReply.flyer_id = event.id;
      this.fyerReply.flyer = event.filename;
      this.fyerReply.hasImageFlyerInSoft = event.hasImageFlyerInSoft
        ? null
        : event.urlImage;
      this.$set(this.fyerReply, "route", event.urlImage);
    },
    async activateEditFlyer() {
      const confirm = await this.showGenericConfirmSwal({
        text: "Do you want to edit the flyer?",
      });

      if (confirm.value) {
        this.returnFlyer = JSON.parse(JSON.stringify(this.fyerReply.flyer_id));
        this.returnFlyerImage = this.fyerReply.route;
        this.editFlyer = true;
      }
    },
    async cancelFlyerEdition() {
      const confirm = await this.showGenericConfirmSwal({
        text: "Changes will be lost!",
      });

      if (confirm.value) {
        this.fyerReply.flyer_id = this.returnFlyer;
        this.fyerReply.route = this.returnFlyerImage;
        this.fyerReply.flyer = null;
        this.fyerReply.hasImageFlyerInSoft = null;
        this.editFlyer = false;
      }
    },
    async updateFlyer() {
      try {
        const confirm = await this.showGenericConfirmSwal({
          text: "The flyer will be save!",
        });

        if (confirm.value) {
          let params = {
            id_reply: this.fyerReply.reply_id,
            id_flyer: this.fyerReply.flyer_id,
            flyer: this.fyerReply.flyer,
            has_image_flyer_in_meta: this.fyerReply.hasImageFlyerInSoft,
          };

          const response = await this.A_UPDATE_FLYER_REPLY(params);

          if (response.status == 200) {
            this.showGenericToast({
              text: "Information was saved",
            });

            //Show in the front the new flyer
            this.reply.flyer_id = this.fyerReply.flyer_id;
            this.reply.flyer_route = this.fyerReply.route;
          }

          this.removePreloader();
          this.editFlyer = false;
          this.fyerReply.flyer = null;
          this.fyerReply.hasImageFlyerInSoft = null;
        }
      } catch (error) {
        throw error;
      }
    },
  },
  async created() {
    await this.A_GET_SPECIAL_FLYERS_ID();
    this.isSpecialFlyer = this.S_SPECIAL_FLYERS.includes(
      this.fyerReply.flyer_id
    );
  },
};
</script>

<style scoped>
.badge-position {
  position: absolute;
  top: 0;
  right: 0;
}

.row-buttons {
  position: absolute;
  bottom: 0;
  margin-bottom: 5px;
  right: 0;
}

.unknownClassFlyer {
  position: absolute;
  top: 10.7rem;
  font-size: 37px;
  left: 7rem;
}

.state-badge {
  font-size: 12px;
  position: absolute;

  top: 5px;
  right: 5px;
}
.padding-little-icons {
  padding: 0px 4px;
  width: 21px;
  height: 23px;
}

.padding-little-icons-cancel {
  padding: 0px 5.5px;
  width: 21px;
  height: 23px;
}
</style>
