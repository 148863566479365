<template>
  <b-modal
    v-model="show"
    title="New Contact"
    title-tag="h3"
    hide-footer
    scrollable
    size="lg"
    no-close-on-backdrop
    no-close-on-esc
    @hidden="close(null)"
  >
    <ValidationObserver ref="form">
      <!-- <span> Añadir componente de Bryan acá</span> -->
      <source-method-contacts
        class="mt-1"
        @getdata="getData"
      ></source-method-contacts>

      <div class="mb-3 mt-1">
        <b-row
          v-if="
            reply.contact_method_id &&
            reply.from_flyer_contact_method === 1 &&
            reply.program_id
          "
        >
          <b-col md="4" lg="4">
            <b-form-group
              id="input-create-lead-10"
              label-class="font-bureau-style font-weight-normal color-gray-input-sn"
              label-for="input-horizontal"
              label="Flyer"
            >
              <ValidationProvider
                v-slot="{ errors }"
                rules="required"
                name="input-create-lead-10, Flyer"
              >
                <div class="content-flyer">
                  <div id="input-create-lead-11" class="d-flex margin-flyer">
                    <b-avatar
                      square
                      class="image-flyer"
                      :src="imageFlyer"
                      size="10rem"
                      icon="card-image"
                      :class="errors[0] ? 'border-error-flyer' : ''"
                    />
                    <div class="d-flex align-items-center">
                      <button
                        class="rounded sub-sources border-0 btn-block btn btn-light"
                        @click="openModalSelectFlyer"
                      >
                        Select
                      </button>
                    </div>

                    <input v-model="lead.flyer" type="radio" class="d-none" />
                  </div>
                  <p v-if="errors[0]" class="text-error-sn text-left">
                    Flyer {{ errors[0] }}
                  </p>
                </div>
              </ValidationProvider>
            </b-form-group>
          </b-col>
          <b-col md="7" lg="7">
            <b-form-group
              label="Replies"
              label-class="font-bureau-style font-weight-normal color-gray-input-sn"
              label-cols-sm="4"
              label-for="input-horizontal"
              label-cols-lg="2"
              content-cols-sm
              content-cols-lg="3"
            >
              <b-form-spinbutton v-model="reply.multiply" min="1" size="sm" />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col md="12 text-left" class="d-flex align-items-center">
            <span class="title-card font-medium-1 my-2">Type</span>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="10">
            <ValidationProvider v-slot="{ errors }" rules="required">
              <b-row>
                <b-col md="3">
                  <b-button
                    :variant="reply.typeReply == 1 ? 'primary' : 'light'"
                    block
                    size="sm"
                    @click="selectTypeReply(1)"
                  >
                    Contact
                  </b-button>
                </b-col>
                <b-col md="3">
                  <b-button
                    :variant="reply.typeReply == 2 ? 'primary' : 'light'"
                    block
                    size="sm"
                    @click="selectTypeReply(2)"
                    >Recovery</b-button
                  >
                </b-col>
                <b-col md="4 pt-2">
                  <div v-if="errors[0]" class="text-danger">
                    Type of Contact {{ errors[0] }}
                  </div>
                </b-col>

                <input v-model="reply.typeReply" type="radio" class="d-none" />
              </b-row>
            </ValidationProvider>
          </b-col>
        </b-row>
      </div>

      <div class="border-top my-3">
        <transition name="fade">
          <div>
            <b-row>
              <b-col cols="12" class="d-flex align-items-center">
                <span class="title-card font-medium-1 my-2"
                  >Extra Information</span
                >
              </b-col>

              <b-col cols="6" class="text-left">
                <b-form-group
                  id="fieldset-horizontal"
                  label-class="font-bureau-style font-weight-normal color-gray-input-sn"
                  label-cols-sm="4"
                  label-for="input-horizontal"
                  label-cols-lg="6"
                  content-cols-sm
                  content-cols-lg="6"
                  label="Did you start dialogue?"
                >
                  <VueToggles
                    class="vue-toggle"
                    height="31"
                    width="90"
                    checked-text="YES"
                    unchecked-text="NO"
                    checked-bg="#FF6A6A"
                    :dot-color="reply.addEvidence ? 'white' : '#FF6A6A'"
                    unchecked-bg="lightgrey"
                    :value="reply.addEvidence"
                    font-weight="bold"
                    :disabled="disableDialogue"
                    @click="onClickToggleAnswer"
                  />
                </b-form-group>
              </b-col>
              <b-col v-if="!reply.addEvidence" cols="6" class="text-left">
                <ValidationProvider
                  v-slot="{ errors }"
                  rules="required"
                  name="MotiveNoAnswer"
                >
                  <b-form-group
                    id="fieldset-horizontal"
                    label="Motive"
                    label-for="st-ad"
                    :state="errors[0] ? false : null"
                    label-class="font-bureau-style font-weight-normal color-gray-input-sn"
                    label-cols-sm="4"
                    label-cols-lg="4"
                    content-cols-sm
                    content-cols-lg="8"
                    class="m-0"
                  >
                    <div
                      class="d-flex justify-content-between align-items-center"
                    >
                      <b-form-select
                        v-model="reply.motiveNoAnswer"
                        class="form-control"
                        :state="errors[0] ? false : null"
                        :options="motivesNoAnswerOptions"
                        text-field="description"
                        value-field="id"
                      />
                      <feather-icon
                        v-if="isCeo || isSupervisor || isTeamLeader"
                        class="text-primary cursor-pointer"
                        size="26"
                        style="margin-left: 1rem"
                        icon="PlusCircleIcon"
                        @click="openEditMotiveModalOn(1)"
                      />
                    </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
            </b-row>
            <b-row v-if="reply.addEvidence" class="mt-2">
              <b-col cols="6" class="text-left">
                <b-form-group
                  id="fieldset-horizontal"
                  label-class="font-bureau-style font-weight-normal color-gray-input-sn"
                  label-cols-sm="4"
                  label-for="input-horizontal"
                  label-cols-lg="6"
                  content-cols-sm
                  content-cols-lg="6"
                  label="Did you get the phone?"
                >
                  <VueToggles
                    class="vue-toggle"
                    height="31"
                    width="90"
                    checked-text="YES"
                    unchecked-text="NO"
                    checked-bg="#FF6A6A"
                    :dot-color="reply.addEvidenceMobile ? 'white' : '#FF6A6A'"
                    unchecked-bg="lightgrey"
                    :value="reply.addEvidenceMobile"
                    font-weight="bold"
                    :disabled="disableMobile"
                    @click="onClickTogglePhone"
                  />
                </b-form-group>
              </b-col>
              <b-col v-if="!reply.addEvidenceMobile" cols="6" class="text-left">
                <ValidationProvider
                  v-slot="{ errors }"
                  rules="required"
                  name="MotiveMobile"
                >
                  <b-form-group
                    id="fieldset-horizontal"
                    label="Motive"
                    label-for="st-ad"
                    :state="errors[0] ? false : null"
                    label-class="font-bureau-style font-weight-normal color-gray-input-sn"
                    label-cols-sm="4"
                    label-cols-lg="4"
                    content-cols-sm
                    content-cols-lg="8"
                    class="m-0"
                  >
                    <div
                      class="d-flex justify-content-between align-items-center"
                    >
                      <b-form-select
                        v-model="reply.motiveNoMobile"
                        class="form-control"
                        :state="errors[0] ? false : null"
                        :options="motivesNoMobileOptions"
                        text-field="description"
                        value-field="id"
                      />
                      <feather-icon
                        v-if="isCeo || isSupervisor || isTeamLeader"
                        class="text-primary cursor-pointer"
                        size="26"
                        style="margin-left: 1rem"
                        icon="PlusCircleIcon"
                        @click="openEditMotiveModalOn(2)"
                      />
                    </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col
                v-if="reply.addEvidenceMobile"
                cols="6"
                class="text-left p-0"
              >
                <ValidationProvider
                  v-slot="{ errors }"
                  rules="required|min:14"
                  name="input-Phone(M)"
                >
                  <standar-form-group label="Phone(M)">
                    <b-input-group>
                      <b-form-input
                        v-model="reply.phonem"
                        v-mask="'(###) ###-####'"
                        :state="errors[0] ? false : null"
                        @input="validateMobile"
                      />
                    </b-input-group>
                  </standar-form-group>
                </ValidationProvider>
              </b-col>
            </b-row>
            <template v-if="reply.addEvidence">
              <template
                v-if="
                  !leadData.first_name || !leadData.last_name || !reply.phonem
                "
              >
                <b-row>
                  <b-col md="6">
                    <b-form-group
                      id="fieldset-horizontal"
                      label-class="font-bureau-style font-weight-normal color-gray-input-sn"
                      label-cols-sm="4"
                      label-for="input-horizontal"
                      label-cols-lg="6"
                      content-cols-sm
                      content-cols-lg="6"
                      label="Filter"
                    >
                      <span
                        v-if="
                          !leadData.first_name ||
                          !leadData.last_name ||
                          !reply.phonem
                        "
                        class="text-danger"
                        >First name, Last name and phone are required</span
                      >
                    </b-form-group>
                  </b-col>
                </b-row>
              </template>
              <template v-else>
                <b-row v-if="!lead.event_id">
                  <b-col md="6">
                    <b-form-group
                      id="fieldset-horizontal"
                      label-class="font-bureau-style font-weight-normal color-gray-input-sn"
                      label-cols-sm="4"
                      label-for="input-horizontal"
                      label-cols-lg="6"
                      content-cols-sm
                      content-cols-lg="6"
                      label="Appointment"
                    >
                      <VueToggles
                        class=""
                        height="31"
                        width="90"
                        checked-text="YES"
                        unchecked-text="NO"
                        checked-bg="#FF6A6A"
                        :dot-color="reply.filterLead ? 'white' : '#FF6A6A'"
                        unchecked-bg="lightgrey"
                        font-weight="bold"
                        :value="reply.filterLead"
                        @click="onClickToggleFilter"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <appointment-add
                  :lead="reply"
                  :modul="2"
                  class="border-top mt-2"
                  v-if="
                    !lead.event_id &&
                    reply.phonem != null &&
                    reply.addEvidenceMobile &&
                    reply.filterLead
                  "
                  @getAppointment="getAppointment"
                />
              </template>
            </template>
          </div>
        </transition>

        <b-row class="mt-2">
          <b-col class="text-right pr-2">
            <b-button
              variant="outline-danger"
              class="mr-1"
              title="Cancel Edit"
              @click="close(false)"
            >
              <feather-icon icon="XIcon" />
              Cancel
            </b-button>
            <b-button
              variant="primary"
              title="Update Personal Information"
              @click="validateReply()"
            >
              <feather-icon icon="CheckIcon" />
              Update
            </b-button>
          </b-col>
        </b-row>
      </div>
    </ValidationObserver>
    <create-edit-motive-modal
      v-if="createEditMotiveModalOn"
      :is-from-reply="isFromReply"
      :type-from-reply="motiveTypeReply"
      @close="closeEditMotiveModalOn"
      @reload="getMotiveLossByType"
    />

    <meta-ads-modal
      v-if="openModal"
      :program-id="reply.program_id"
      :selected-flyer="[{ id: reply.flyerId }]"
      @selectFlyer="selectFlyer($event)"
      @hidden="openModal = false"
    />
  </b-modal>
</template>

<script>
// Services
import SNLeadsService from "@/views/social-network/services/leads";
import { mapActions, mapGetters, mapState } from "vuex";
import VueToggles from "vue-toggles";
import SettingsService from "@/views/social-network/views/settings/settings.service";
import CreateEditMotiveModal from "@/views/social-network/views/settings/modals/CreateEditMotiveModal.vue";
import StandarFormGroup from "@/views/social-network/views/leads/components/StandarFormGroup.vue";
import TaskAddLater from "@/views/social-network/views/leads/components/lead-create/task-create-lead-tab/TaskAddLater.vue";
import AppointmentAdd from "@/views/social-network/views/leads/components/lead-create/appointment-create-lead/AppointmentAdd.vue";
import SourceMethodContacts from "@/views/social-network/views/leads/components/lead-create/SourceCardandMethodContact.vue";
import MetaAdsModal from "@/views/creative/views/meta/components/MetaAdsModal.vue";
export default {
  name: "CreateContactModal",
  components: {
    VueToggles,
    CreateEditMotiveModal,
    StandarFormGroup,
    AppointmentAdd,
    TaskAddLater,
    SourceMethodContacts,
    MetaAdsModal,
  },
  props: {
    reply: {
      type: Object,
      default: () => ({}),
    },
    personalMobile: {
      type: Object,
    },
    personalInfo: {
      type: Object,
    },
    lead: {
      type: Object,
    },
  },
  data() {
    return {
      titleFanPageProgram: null,
      multiplyValidation: false,
      show: false,
      isFromReply: true,
      imageFlyer: null,
      disableMobile: false,
      createEditMotiveModalOn: false,
      editMobile: false,
      currentPhone: "",
      sources: [],
      parent_source: null,
      programs_fanpage: [],
      showAddMotive: false,
      motivesNoAnswerOptions: [],
      motivesNoMobileOptions: [],
      motivesNoFilterOptions: [],
      typeNoAnswer: 1,
      typeNoMobile: 2,
      typeNoFilter: 3,
      motiveTypeReply: null,
      titleProgram: "",
      optionsMotive: [
        {
          description: "Select Motive",
          id: null,
        },
      ],
      businessFlyerOrganicId: "",
      boostCreditFlyerOrganicId: "",
      creditExpertsFlyerOrganicId: "",
      debtSolutionFlyerOrganicId: "",
      taxResearchFlyerOrganicId: "",

      businessFlyerPhoneId: "",
      boostCreditFlyerPhoneId: "",
      creditExpertsFlyerPhoneId: "",
      debtSolutionFlyerPhoneId: "",
      taxResearchFlyerPhoneId: "",

      businessFlyerRecoveryId: "",
      boostCreditFlyerRecoveryId: "",
      creditExpertsFlyerRecoveryId: "",
      debtSolutionFlyerRecoveryId: "",
      taxResearchFlyerRecoveryId: "",

      openModal: false,

      dataLastReply: {},
      disableDialogue: false,

      leadData: {},
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    ...mapState("SocialNetworkLeadsStore", [
      "S_FLYERS",
      "S_ORGANIC_FLYERS",
      "S_PHONE_FLYERS",
      "S_RECOVERY_FLYERS",
      "S_STATES_LEADS",
      "S_SUB_SOURCES",
      "S_FAN_PAGE_PROGRAMS",
    ]),

    isChangeFanPage() {
      return this.lead.fanpage_id;
    },
    isFacebook() {
      return this.parent_source === 1;
    },
    isGoogle() {
      return this.parent_source === 2;
    },
    saveWhenNoHavingEnoughInformation() {
      return (
        this.reply.addEvidence === true &&
        this.reply.addEvidenceMobile === true &&
        (!this.leadData.first_name ||
          !this.leadData.last_name ||
          !this.reply.phonem) &&
        this.reply.motiveNoFilter == null
      );
    },
  },
  async created() {
    this.addPreloader();
    await this.getMotiveLossByType();
    await this.getLeadLastReply();
    await this.getLeadById();
    this.isMobile();
    this.currentPhone = this.reply.phonem;
    this.show = true;
    await this.A_GET_FLYER_ORGANIC_BY_PROGRAM();
    await this.A_GET_FLYER_PHONE_BY_PROGRAM();
    await this.A_GET_FLYER_RECOVERY_BY_PROGRAM();
    this.setProgramOrganicFlyer();
    this.setProgramPhoneFlyer();
    this.setProgramRecoveryFlyer();
    this.setUpToggles();
    this.removePreloader();

    this.reply.program_id = null;
    this.reply.source_id = null;
    this.reply.contact_method_id = null;
    this.reply.from_flyer_contact_method = null;
    this.reply.multiply = 1;
  },
  mounted() {
    this.cleanElements();
  },
  methods: {
    openModalSelectFlyer() {
      this.openModal = !this.openModal;
    },
    getAppointment(data) {
      // if (data.appTo != null) {
      //   this.reply.filterLead = true
      // }
    },
    ...mapActions("SocialNetworkLeadsStore", [
      "A_GET_FLYERS",
      "A_GET_FLYER_ORGANIC_BY_PROGRAM",
      "A_GET_FLYER_PHONE_BY_PROGRAM",
      "A_GET_FLYER_RECOVERY_BY_PROGRAM",
    ]),
    selectFlyer(event) {
      this.reply.flyerId = event.id;
      this.reply.flyer = event.filename;
      this.reply.has_image_flyer_in_meta = event.hasImageFlyerInSoft
        ? null
        : event.urlImage;
      this.lead.flyer = event.id;
      this.imageFlyer = event.urlImage;
      this.openModalSelectFlyer();
    },
    async getLeadById() {
      const lead = await SNLeadsService.getLeadById({
        leadId: this.$route.params.id,
      });
      if (lead.status === 200) {
        this.leadData = lead.data[0];
      }
    },
    isMobile() {
      this.reply.phonem = this.personalMobile.phonem;
      if (this.reply.phonem) {
        this.disableMobile = true;
      }

      return this.reply.phonem;
    },
    setProgramOrganicFlyer() {
      if (this.S_ORGANIC_FLYERS.length > 4) {
        this.businessFlyerOrganicId = this.S_ORGANIC_FLYERS[0].flyer_id;
        this.boostCreditFlyerOrganicId = this.S_ORGANIC_FLYERS[1].flyer_id;
        this.creditExpertsFlyerOrganicId = this.S_ORGANIC_FLYERS[2].flyer_id;
        this.debtSolutionFlyerOrganicId = this.S_ORGANIC_FLYERS[3].flyer_id;
        this.taxResearchFlyerOrganicId = this.S_ORGANIC_FLYERS[4].flyer_id;
      }
    },
    setProgramPhoneFlyer() {
      if (this.S_PHONE_FLYERS.length > 4) {
        this.businessFlyerPhoneId = this.S_PHONE_FLYERS[0].flyer_id;
        this.boostCreditFlyerPhoneId = this.S_PHONE_FLYERS[1].flyer_id;
        this.creditExpertsFlyerPhoneId = this.S_PHONE_FLYERS[2].flyer_id;
        this.debtSolutionFlyerPhoneId = this.S_PHONE_FLYERS[3].flyer_id;
        this.taxResearchFlyerPhoneId = this.S_PHONE_FLYERS[4].flyer_id;
      }
    },
    setProgramRecoveryFlyer() {
      if (this.S_RECOVERY_FLYERS.length > 4) {
        this.businessFlyerRecoveryId = this.S_RECOVERY_FLYERS[0].flyer_id;
        this.boostCreditFlyerRecoveryId = this.S_RECOVERY_FLYERS[1].flyer_id;
        this.creditExpertsFlyerRecoveryId = this.S_RECOVERY_FLYERS[2].flyer_id;
        this.debtSolutionFlyerRecoveryId = this.S_RECOVERY_FLYERS[3].flyer_id;
        this.taxResearchFlyerRecoveryId = this.S_RECOVERY_FLYERS[4].flyer_id;
      }
    },
    // async onInputChange(text) {},
    async validateMobile(mobile) {
      if (mobile.length === 14) {
        try {
          const validate = await SNLeadsService.verifyMobile({
            mobile,
          });

          if (validate.status === 200) {
            if (validate.data.error_code == "MOBILE_EXISTS") {
              const verifyMobile = await this.showConfirmSwal(
                `The phone number already exists: ${validate.data.message}`,
                "",
                {
                  confirmButtonText: "OPEN LEAD",
                }
              );

              if (verifyMobile.value) {
                window.open(
                  `/socialnetwork/leads/new/dashboard/${validate.data.lead_id}`,
                  "_blank"
                );
              }
            }
          }
        } catch (error) {
          this.showErrorSwal(error);
        }
      }
    },
    closeEditMotiveModalOn() {
      this.createEditMotiveModalOn = false;
    },
    openEditMotiveModalOn(type) {
      this.motiveTypeReply = type;
      this.createEditMotiveModalOn = true;
    },
    async getMotiveLossByType() {
      const data = await SettingsService.getMotiveLossByType({
        type_id: this.typeNoAnswer,
      });
      if (data.status === 200) {
        this.reply.motiveNoAnswer = null;
        this.motivesNoAnswerOptions = [...this.optionsMotive, ...data.data];
      }
      const result = await SettingsService.getMotiveLossByType({
        type_id: this.typeNoMobile,
      });
      if (result.status === 200) {
        this.reply.motiveNoMobile = null;
        this.motivesNoMobileOptions = [...this.optionsMotive, ...result.data];
      }

      const dataMotive = await SettingsService.getMotiveLossByType({
        type_id: this.typeNoFilter,
      });
      if (dataMotive.status === 200) {
        this.reply.motiveNoFilter = null;
        this.motivesNoFilterOptions = [
          ...this.optionsMotive,
          ...dataMotive.data,
        ];
      }
    },
    getData(params) {
      this.reply.program_id = params.program_id;
      this.reply.source_id = params.source_id;
      this.reply.contact_method_id = params.contact_method_id;
      this.reply.from_flyer_contact_method = params.from_flyer_contact_method;
      // let request = {
      //   state: this.lead.state,
      //   program_id: params.program_id,
      // };
      // if (params.contact_method_id != "") {
      //   this.A_GET_FLYERS(request);
      // }

      this.$forceUpdate();
    },
    cleanElements() {
      this.reply.idProgram = null;
      this.reply.idSubSource = null;
      this.reply.typeReply = 1;
      this.reply.idMethod = null;
      this.parent_source = null;
    },
    onChangeOrganic() {
      this.reply.is_organic = !this.reply.is_organic;
      this.reply.flyerId = null;
      this.imageFlyer = null;
    },
    activateInformationEdit() {
      this.currentPhone = this.reply.phonem;
      this.editMobile = true;
      this.disableMobile = false;
    },
    cancelInformationEdit() {
      this.editMobile = false;
      this.reply.phonem = this.currentPhone;
      this.disableMobile = true;
    },
    selectTypeReply(id) {
      this.reply.typeReply = id;
      if (id === 2) {
        this.reply.is_organic = null;
        this.reply.flyerId = null;
        this.imageFlyer = null;
      }
    },
    async validateReply() {
      const validate = await this.$refs.form.validate();

      if (validate) {
        const confirm = await this.showGenericConfirmSwal({});

        if (confirm.value) {
          await this.saveReply();
        }
      }
    },
    async updateInformation() {
      try {
        const confirm = await this.showConfirmSwal(
          "Are you sure?",
          "The phone will be save!"
        );
        if (confirm.isConfirmed) {
          let params = {};
          params = {
            id_lead: this.$route.params.id,
            id_user: this.currentUser.user_id,
            module_id: this.currentUser.modul_id,
            phonem: this.reply.phonem,
            typee: 3,
          };
          await this.updateFunction(params);
        }
      } catch (e) {
        this.showErrorSwal(e);
      }
    },
    async updateFunction(params) {
      this.addPreloader();
      try {
        const response = await SNLeadsService.postUpdateLeadInformationFields(
          params
        );
        if (response.status === 200) {
          this.removePreloader();

          this.showGenericToast({
            title: "Updated",
            text: "Information was saved",
          });
          this.disableMobile = true;
          this.editMobile = false;
          this.currentPhone = this.reply.phonem;
        }
      } catch (e) {
        this.showErrorSwal(e);
      }
    },
    async getLeadLastReply() {
      try {
        const response = await SNLeadsService.getLeadLastReply({
          leadId: this.$route.params.id,
        });
        if (response.status === 200) {
          this.dataLastReply = response.data[0];
        }
      } catch (e) {
        this.showErrorSwal(e);
      }
    },
    setUpToggles() {
      this.reply.addEvidenceMobile = this.personalMobile.phonem !== null;
      this.reply.addEvidence = this.dataLastReply?.dialogue != "NO";
      if (this.reply.addEvidence) this.disableDialogue = true;
    },
    onClickToggleAnswer() {
      this.reply.addEvidence = !this.reply.addEvidence;
      this.reply.motiveNoAnswer = null;
    },
    onClickTogglePhone() {
      this.reply.phonem = null;
      this.reply.addEvidenceMobile = !this.reply.addEvidenceMobile;
      this.reply.motiveNoMobile = null;
    },
    onClickToggleFilter() {
      if (this.reply.filterLead) {
        this.$set(this.reply, "filterLead", false);
      } else {
        this.$set(this.reply, "filterLead", true);
      }
      this.reply.motiveNoFilter = null;
    },
    async saveReply() {
      try {
        let reply = this.reply;
        this.reply.id_lead = this.$route.params.id;
        this.reply.user_id = this.currentUser.user_id;
        this.personalMobile.id_lead = reply.id_lead;

        reply.phonem = reply.motiveNoMobile ? null : reply.phonem;

        // add new contact
        const response = await SNLeadsService.saveLeadReply(reply);
        if (response.status === 200) {
          this.$emit("updatedMobile");
          this.$emit("onAddContact");
          this.showToast();
          this.close(response.data);
        }
      } catch (error) {
        this.showErrorSwal(error);
      }
    },
    getFlyerOrganicProgram(program) {
      switch (program) {
        case 1:
          return this.businessFlyerOrganicId;
        case 2:
          return this.boostCreditFlyerOrganicId;
        case 3:
          return this.creditExpertsFlyerOrganicId;
        case 4:
          return this.debtSolutionFlyerOrganicId;
        case 5:
          return this.taxResearchFlyerOrganicId;
        default:
          return null;
      }
    },
    getFlyerPhoneProgram(program) {
      switch (program) {
        case 1:
          return this.businessFlyerPhoneId;
        case 2:
          return this.boostCreditFlyerPhoneId;
        case 3:
          return this.creditExpertsFlyerPhoneId;
        case 4:
          return this.debtSolutionFlyerPhoneId;
        case 5:
          return this.taxResearchFlyerPhoneId;
        default:
          return null;
      }
    },
    getFlyerRecoveryProgram(program) {
      switch (program) {
        case 1:
          return this.businessFlyerRecoveryId;
        case 2:
          return this.boostCreditFlyerRecoveryId;
        case 3:
          return this.creditExpertsFlyerRecoveryId;
        case 4:
          return this.debtSolutionFlyerRecoveryId;
        case 5:
          return this.taxResearchFlyerRecoveryId;
        default:
          return null;
      }
    },
    close(data) {
      this.show = false;
      this.reply.is_organic = false;
      this.reply.addEvidence = true;
      this.reply.addEvidenceMobile = true;
      this.reply.appTitle = "";
      this.reply.flyerId = null;
      this.imageFlyer = null;
      this.reply.appSeller = null;
      this.reply.appDescription = "";
      this.reply.appDate = "";
      this.reply.appFrom = "";
      this.reply.appTo = "";
      this.reply.created_users = null;
      this.reply.appType = "event";
      this.reply.dateSp = null;
      this.reply.date = "";
      this.reply.from = "";
      this.reply.to = "";
      this.reply.dateEn = null;
      this.reply.hourMsn = null;
      this.reply.state_h = "CA";
      this.reply.location = "";
      this.reply.id_lead = this.lead.id;
      this.$emit("onClose", data);
    },
  },
};
</script>

<style scoped lang="scss">
.pl-30 {
  padding-left: 30px !important;
}
.container-flyers {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}
.pr-30 {
  padding-right: 30px !important;
}

.font-07 {
  font-size: 8pt;
}
.sub-sources {
  padding: 6px 10px;
  font-size: 14px;
  transition: 0.3s all ease-in-out;
}

.image-flyer {
  height: 168px;
  width: 168px;
  object-fit: cover;
  margin-right: 20px;
  span {
    cursor: pointer;
    border: 3px solid transparent;
    transition: 0.3s all ease-in-out;
  }
}

.select-flyer {
  span {
    cursor: pointer;
    border-color: #fc424a;
    border-width: 5px !important;
  }
}
.dark-layout {
  .select-flyer {
    span {
      cursor: pointer;
      border-color: #fc424a;
    }
  }
}
</style>
