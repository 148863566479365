<template>
  <b-modal
    v-model="show"
    :title="
      (type == 1 ? 'New Note' : 'History Note') + ' (Lead: ' + leadName + ')'
    "
    title-tag="h3"
    hide-footer
    size="xmd"
    @hidden="close"
  >
    <validation-observer ref="form">
      <div>
        <b-row>
          <b-col lg="12" v-if="type == 2">
            <b-table small :fields="table.fields" :items="notes">
              <template #cell(created_by)="data">
                {{ data.item.created_by_name }} |
                {{ data.item.created_at | myDateGlobal }}
              </template>

              <template #cell(important)="data">
                <input
                  type="radio"
                  name="notes"
                  :id="'imp' + data.item.id"
                  @click="importantNote(data.item.id)"
                  :checked="data.item.important == 1 ? true : false"
                  :disabled="onlyRead"
                />
              </template>
            </b-table>
          </b-col>
          <b-col lg="12" v-if="type == 1">
            <validation-provider rules="required" #default="{ errors }">
              <b-form-group :state="errors.length > 0 ? false : null">
                <SmartNoteInput
                  ref="smartNoteInput"
                  v-model="text"
                  @original="handleOriginalText"
                  :show-optimize="true"
                  required
                >
                  <template v-slot:more-items>
                    <div class="d-flex align-items-center gap-10">
                      <div
                        class="select-card"
                        @click.prevent="important = !important"
                        :class="{ active: important }"
                      >
                        <span>Important</span>
                        <b-form-checkbox v-model="important" />
                      </div>
                    </div>
                  </template>
                </SmartNoteInput>

                <span class="text-danger" v-if="!useOptimize">
                  Optimized message is required
                </span>
              </b-form-group>
            </validation-provider>

            <b-form-group>
              <b-row>
                <b-col> </b-col>
                <b-col class="text-right">
                  <b-button
                    variant="primary"
                    @click="saveNote"
                    :disabled="!useOptimize"
                  >
                    Save
                  </b-button>
                </b-col>
              </b-row>
            </b-form-group>
          </b-col>
        </b-row>
      </div>
    </validation-observer>
  </b-modal>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import SmartNoteInput from "@/commons/smart-input/SmartNoteInput.vue";
import { required } from "@validations";

// Services
import NotesService from "@/views/crm/services/notes";

export default {
  props: {
    notes: {
      type: Array,
    },
    leadName: {
      type: String,
    },
    type: {
      type: Number,
      default: 1,
    },
    userId: {
      type: Number,
    },
    leadId: {
      type: Number,
    },
    imp: {
      type: Boolean,
    },
    replyId: {
      type: Number,
    },
    oldReply: {
      type: Boolean,
    },
  },
  components: {
    ValidationObserver,
    ValidationProvider,
    SmartNoteInput,
  },
  data() {
    return {
      show: false,

      // Validations
      required,

      table: {
        fields: [
          { key: "text", label: "Note" },
          {
            key: "created_by",
            label: "Created By",
            thStyle: { width: "300px" },
          },
          { key: "important", label: "Important", thStyle: { width: "100px" } },
        ],
      },

      text: "",
      important: this.imp,
      errorText: false,
      originalText: "",
      useOptimize: false,
    };
  },
  mounted() {},
  computed: {
    onlyRead() {
      return this.module == 18;
    },
  },
  methods: {
    handleOriginalText(text) {
      this.originalText = text;
      this.useOptimize = true;
    },
    async importantNote(id) {
      try {
        const confirm = await this.showGenericConfirmSwal({
          text: "You are going to change the important note!",
        });

        if (confirm.value) {
          const response = await NotesService.postMakeImportantNote({
            id: id,
            user_id: this.userId,
            lead_id: this.leadId,
            reply_id: this.replyId,
            oldReply: this.oldReply,
          });

          if (response.status == 200) {
            this.$emit("newImportant", response.data[0]);

            this.showGenericToast({
              text: "Successful operation",
            });

            this.close();
          }
        }
      } catch (error) {
        throw error;
      }
    },
    async saveNote() {
      try {
        const validate = await this.$refs.form.validate();

        if (validate) {
          this.addPreloader();

          const response = await NotesService.postCreateNote({
            user_id: this.userId,
            lead_id: this.leadId,
            text: this.text,
            important: this.important == true ? 1 : 0,
            reply_id: this.replyId,
            oldReply: this.oldReply,
          });

          if (response.status == 200) {
            this.$emit("newImportant", response.data[0]);

            this.showGenericToast({
              text: "Successful operation",
            });

            this.close();
          }

          this.removePreloader();
        }
      } catch (error) {
        this.removePreloader();
        throw error;
      }
    },
    close() {
      this.$emit("onClose");
    },
  },
  created() {
    this.show = true;
  },
};
</script>

<style lang="scss" scoped>
.select-card {
  width: 200px;
  height: 40px;
  border-radius: 10px;
  border: 1px solid #e4e3e0;
  padding: 0.5rem 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.1rem;
  font-weight: bold;
  cursor: pointer;
  &.active {
    border: 1px solid #0077e6;
  }
}
</style>
